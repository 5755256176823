import ReactDOM from "react-dom";
import "firebase/firestore";
import React from "react";




class ProportionalMatchDetails extends React.Component  {
    _isMounted = false;

constructor(props) {
    super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this)
    this.state = {
        matchID: "TestID"
        };


  };
  
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  saveAndContinue (e) {
    e.preventDefault()

    
  
      var data = {
          gameEndsAt: ReactDOM.findDOMNode(this.refs.gameEndsAt).value,
          gameType: ReactDOM.findDOMNode(this.refs.gameType).value,
          matchID: ""
        };

        
        this.props.nextStep();
        this.props.saveValues(data);


    }

  render() {
    return (
        <div>
            
        <h1 style={{  marginTop: "30px", marginBottom: "20px" }}> A few more details..</h1>
        <p>Game ends at</p>
        <input
        class="form-control"
          type="number"
          ref="gameEndsAt"
          placeholder="100"
            style={{ marginBottom: "0.8rem" }}
          defaultValue={this.props.matchValues.gameEndsAt}
        />
        <select class="custom-select custom-select-sm" style={{  marginBottom: "20px" }}  ref="gameType"
        defaultValue={this.props.matchValues.gameType}>
          <option value="Points">Points</option>
				<option value="Ends">Ends</option>
    
			</select>
        <button onClick={this.saveAndContinue} className="btn btn-primary">Start Match</button>
      </div>
      
    );
  }
}
export default ProportionalMatchDetails
