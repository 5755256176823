import React, { Fragment, Component } from "react";
import fire from "../config/fire";
import firebase from "firebase";
import "firebase/firestore";
import VirtualList from 'react-virtual-list';




const options = {
 // container: this.refs.container, // use this scrollable element as a container
  initialState: {
    firstItemIndex: 0, // show first ten items
    lastItemIndex: 0,  // during initial render
  },
};




class SavedMatchList extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.saveAndContinue = this.saveAndContinue.bind(this);
   // this.getFirstName = this.getFirstName.bind(this);
    this.state ={
//firstname: ""
playerTwoNames: [],

listOpen: false,
data: "",

matchArray: [],

    }

    

    
  }

  
  MyList = ({
    virtual,
    itemHeight,
  }) => (
    <Fragment style={virtual.style}>
  
      {Object.keys(virtual.items).map((item,i) => 
      
        <tr><td key={virtual.items[item].matchID} style={{height: itemHeight}}>
          {virtual.items[item].playerOneName} v {virtual.items[item].playerTwoName} at {virtual.items[item].matchVenue} on {(virtual.items[item].matchDate)}
       </td><td> <button onClick={() => { this.saveAndContinue(virtual.items[item].matchID, "Singles") }} className="btn btn-primary">Details</button>
    
        </td></tr>
      
      )}
   </Fragment> 
    
  );








  
  saveAndContinue = (e,f) => {
    //preventDefault()
  
      // Get values via this.refs
      var data = {
        matchID: e,
        matchType: f
      };
  
      this.props.saveValues(data);
        this.props.nextStep();
       
  
    }
  
  MyVirtualList = VirtualList(options)(this.MyList);

  
  getMatchList() {

    var that = this
    
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
    
   var docRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Singles");

   docRef.get().then(querySnapshot => {
    querySnapshot.forEach(doc => {
        that.setState({ data: doc.data().items })
        var newArray = that.state.playerTwoNames.concat(doc.data().matchVenue);
        var newArray2 = that.state.matchArray.concat(doc.data());
        that.setState({ matchArray: newArray2 })
        that.setState({ playerTwoNames: newArray })
        console.log(doc.data())
    });
});
} else {
  // No user is signed in.
}
});
} 


  logOut() {
    fire.auth().signOut();
  }

componentDidMount() {
  this.getMatchList()

}










  render() { 
    return (
      <div>




<h1>Saved Singles Matches</h1>

<div>
<div style={{width:"80%"}}>
     <table>
      <this.MyVirtualList
  items={this.state.matchArray}
  itemHeight={100}
/>
</table>
</div>
   
</div>
</div>

  
      
  
    );
  }
}



export default SavedMatchList;
