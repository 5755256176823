import React from "react";
import logo from "../img/rollUpLogo.png";



var img = "img";

class Header extends React.Component {
  





  

  render() {
    return (
      <div className="header" style={{ borderBottom: "solid 1px #f0f0f5", height: "70px" }}>
        <div style={{ float: "left", marginTop: "10px"}}>
          <img src={logo} alt="rollUp Logo" style={{ height: "50px" }} />
        </div>
        <div style={{ float: "right", marginTop: "15px" }}>
        
        
        </div>
      </div>
    );
  }
}
export default Header;
