import Proportionalmatch from './proportionalmatch'



var React = require("react");




 class ProportionalMatchScreen extends React.Component {

    
  render() {    

        
    return (
        
        
        <div>
      <Proportionalmatch playerOneNameEntry={ this.props.matchValues.playerOneName } 
                    playerOneScoreEntry={ this.props.matchValues.playerOneStartingScore } 
                    playerTwoNameEntry={ this.props.matchValues.playerTwoName } 
                    playerTwoScoreEntry={ this.props.matchValues.playerTwoStartingScore } 
                    matchVenueEntry= { this.props.matchValues.matchVenue }
                    gameEndsAtEntry= { this.props.matchValues.gameEndsAt }
                    gameTypeEntry= { this.props.matchValues.gameType }
                    matchIDEntry = {  this.props.matchValues.matchID }
                    nextStep = { this.props.nextStep }
                    saveValues = { this.props.saveValues }
                    playerSelectEntry = { this.props.matchValues.playerSelect }
      />
      </div>
  )
    }
}


export default ProportionalMatchScreen