import ReactDOM from "react-dom";

var React = require("react");
var createReactClass = require("create-react-class");

var SinglesPlayerNameFields = createReactClass({
  render: function () {
    return (
      <div>
        <h1 style={{  marginTop: "30px", marginBottom: "20px" }}> Match Details</h1>
        <div className="form-row">
          <input
            className="form-control"
            type="text"
            ref="playerOneName"
            placeholder="Player One Name"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.playerOneName}
          />
        </div>
        <div className="form-row">
          <input
            className="form-control"
            type="text"
            ref="playerTwoName"
            placeholder="Player Two Name"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.playerTwoName}
          />
        </div>

        <div className="form-row">
          <input
            className="form-control"
            type="text"
            ref="matchVenue"
            placeholder="Match Venue"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.matchVenue}
          />
        </div>
 <select class="custom-select custom-select-sm" style={{  marginBottom: "20px" }}  ref="playerSelect"
        defaultValue={this.props.matchValues.playerSelect}>
				<option value="playerOne">I am Player One</option>
    <option value="playerTwo">I am Player Two</option>
    <option value="noStats">Don't include this match in my stats</option>
			</select>
        <button className="btn btn-primary" onClick={this.saveAndContinue}>
          Continue
        </button>
      </div>
    );
  },

  saveAndContinue: function (e) {
    e.preventDefault();

    // Get values via this.refs
    var data = {
      playerOneName: ReactDOM.findDOMNode(this.refs.playerOneName).value,
      playerTwoName: ReactDOM.findDOMNode(this.refs.playerTwoName).value,
      matchVenue: ReactDOM.findDOMNode(this.refs.matchVenue).value,
      playerSelect: ReactDOM.findDOMNode(this.refs.playerSelect).value,
    };

    this.props.saveValues(data);
    this.props.nextStep();
  },
});

export default SinglesPlayerNameFields;
