import ReactDOM from "react-dom";
var React = require("react");




class SuccessPairs extends React.Component
{
  constructor(props) {
    super(props);
    this.mailGun = this.mailGun.bind(this)
  }

  mailGun(e) {
    e.preventDefault();
    const mailgun = require("mailgun-js");
    const DOMAIN = 'mg.rollupbowls.com.au';
    const RECIPIENT = ReactDOM.findDOMNode(this.refs.emailSend).value
    console.log(RECIPIENT)
    const mg = mailgun({apiKey: 'aa17a0e1fe121808a4e0bbcc99b04b3d-cb3791c4-c9226b97', domain: DOMAIN});
    const data = {
      from: 'rollUp Scorecard <donotreply@rollupbowls.com.au>',
      to: RECIPIENT,
      subject: 'rollUp Scorecard - Match Result',
      text: 'Hi, we have been asked to send you a match score from the rollUp Scorecard App. In the game between ' +
      this.props.matchValues.playerOneName +  ' and ' + this.props.matchValues.playerTwoName + ' at ' + this.props.matchValues.matchVenue + ', the score was ' +
      this.props.matchValues.playerOneName + ' (' + this.props.matchValues.playerOneScore + ') to ' + this.props.matchValues.playerTwoName + ' (' + this.props.matchValues.playerTwoScore + ') after ' + this.props.matchValues.ends + ' ends.Find out more and get your free account at rollupbowls.com.au'
    };
    
    mg.messages().send(data, function (error, body) {
      console.log(body);
      console.log('message sent to' + RECIPIENT)
    });
  
  } 


  render() {
    return (
      <div>
        <h2>Game Over!</h2>
        <p>
        Match won by { this.props.matchValues.winnerName }</p>
        <p>{ this.props.matchValues.playerOneScore } - { this.props.matchValues.playerTwoScore }</p>
       <p> after { this.props.matchValues.ends } ends </p>
       <form>
       <div className="form-row">
       <div class="col-sm-3">
         <p>Email results:</p>
         </div>
         <div class="col-sm-7">
         <input
        class="form-control"
          type="text"
          ref="emailSend"
          placeholder="Enter email address"
            style={{ marginBottom: "0.8rem" }}
        />
        </div>
        <p>
       <button onClick={this.mailGun} return false className="btn btn-primary">Send</button>
       </p>
      </div>
       </form>
       <p><a href="/Savedmatches">View saved matches</a></p>
        
      </div>
    );
  }
}

export default SuccessPairs
