import ReactDOM from "react-dom";

var React = require("react");
var createReactClass = require("create-react-class");

var NameFields = createReactClass({
  render: function () {
    return (
      <div>
        <h1 style={{  marginTop: "30px", marginBottom: "20px" }}> Get started with your free rollUp Bowls Account</h1>
        <div className="form-row">
          <input
            className="form-control"
            type="text"
            ref="firstname"
            placeholder="First Name"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.fieldValues.firstname}
          />
        </div>
        <div className="form-row">
          <input
            className="form-control"
            type="text"
            ref="lastname"
            placeholder="Last Name"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.fieldValues.lastname}
          />
        </div>
        <div className="form-row">
          <input
            className="form-control"
            type="text"
            ref="mobile"
            placeholder="Mobile Number"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.fieldValues.mobile}
          />
        </div>

        <button className="btn btn-primary" onClick={this.saveAndContinue}>
          Get Started
        </button>
      </div>
    );
  },

  saveAndContinue: function (e) {
    e.preventDefault();

    // Get values via this.refs
    var data = {
      firstname: ReactDOM.findDOMNode(this.refs.firstname).value,
      lastname: ReactDOM.findDOMNode(this.refs.lastname).value,
      mobile: ReactDOM.findDOMNode(this.refs.mobile).value,
    };

    this.props.saveValues(data);
    this.props.nextStep();
    console.log('This ran')
  },
});

export default NameFields;
