import ReactDOM from "react-dom";

var React = require("react");
var createReactClass = require("create-react-class");

var ClubFields = createReactClass({
  render: function () {
    return (
      <div>
        <h1 style={{  marginTop: "30px", marginBottom: "20px" }}> A few more details..</h1>
        <input
        class="form-control"
          type="text"
          ref="bowlsClub"
          placeholder="Bowls Club"
            style={{ marginBottom: "0.8rem" }}
          defaultValue={this.props.fieldValues.bowlsClub}
        />

        
        <input
        class="form-control"
          type="text"
          ref="postcode"
          placeholder="Postcode"
            style={{ marginBottom: "0.8rem" }}
          defaultValue={this.props.fieldValues.postcode}
        />

        <button onClick={this.saveAndContinue} className="btn btn-primary">Continue</button>
      </div>
    );
  },

  saveAndContinue: function (e) {
    e.preventDefault();

    // Get values via this.refs
    var data = {
      bowlsClub: ReactDOM.findDOMNode(this.refs.bowlsClub).value,
      postcode: ReactDOM.findDOMNode(this.refs.postcode).value,
    };

    this.props.saveValues(data);
    this.props.nextStep();
  },
});

export default ClubFields;
