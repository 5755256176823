import Singlesmatch from './singlesmatch'


var React = require("react");




 class MatchScreen extends React.Component {

    
  render() {    
    const playerOneName = this.props.matchValues.playerOneName;
    console.log(playerOneName)

        
    return (
        
        
        <div>
      <Singlesmatch playerOneNameEntry={ this.props.matchValues.playerOneName } 
                    playerTwoNameEntry={ this.props.matchValues.playerTwoName } 
                    matchVenueEntry= { this.props.matchValues.matchVenue }
                    gameEndsAtEntry= { this.props.matchValues.gameEndsAt }
                    gameTypeEntry= { this.props.matchValues.gameType }
                    matchIDEntry = {  this.props.matchValues.matchID }
                    playerSelectEntry = { this.props.matchValues.playerSelect }
                    nextStep = { this.props.nextStep }
                    saveValues = { this.props.saveValues }
      />
      </div>
  )
    }
}


export default MatchScreen