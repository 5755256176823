import ReactDOM from "react-dom";

var React = require("react");
var createReactClass = require("create-react-class");

var FoursPlayerNameFields = createReactClass({
  render: function () {
    return (
      <div>
        <h1 style={{  marginTop: "30px", marginBottom: "20px" }}> Match Details</h1>
        <p>Team One</p>
        <div className="form-row">
        <div class="col">
          <input
            className="form-control"
            type="text"
            ref="teamOneName"
            placeholder="Team Name (Optional)"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.teamOneName}
          />
        </div>
        </div>
        <div className="form-row">
        <div class="col">
          <input
            className="form-control"
            type="text"
            ref="teamOneLead"
            placeholder="Lead"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.teamOneLead}
          />
          </div><div class="col">
                   <input
            className="form-control"
            type="text"
            ref="teamOneSecond"
            placeholder="Second"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.teamOneSecond}
          />
          </div>
        </div>
        <div className="form-row">
        <div class="col">
          <input
            className="form-control"
            type="text"
            ref="teamOneThird"
            placeholder="Third"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.teamOneThird}
          />
          </div><div class="col">
                   <input
            className="form-control"
            type="text"
            ref="teamOneSkip"
            placeholder="Skip"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.teamOneSkip}
          />
          </div>
        </div>
        <p>Team Two</p>
        <div className="form-row">
        <div class="col">
          <input
            className="form-control"
            type="text"
            ref="teamTwoName"
            placeholder="Team Name (Optional)"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.teamTwoName}
          />
        </div>
        </div>
        <div className="form-row">
        <div class="col">
          <input
            className="form-control"
            type="text"
            ref="teamTwoLead"
            placeholder="Lead"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.teamTwoLead}
          />
          </div>
          <div class="col">
           <input
            className="form-control"
            type="text"
            ref="teamTwoSecond"
            placeholder="Second"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.teamTwoSecond}
          />
          </div>
        </div>
        <div className="form-row">
        <div class="col">
          <input
            className="form-control"
            type="text"
            ref="teamTwoThird"
            placeholder="Third"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.teamTwoThird}
          />
          </div>
          <div class="col">
           <input
            className="form-control"
            type="text"
            ref="teamTwoSkip"
            placeholder="Skip"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.teamTwoSkip}
          />
          </div>
        </div>
        <p>Which team are you playing on? This is used to set your overall stats</p>
        <select class="custom-select custom-select-sm" style={{  marginBottom: "20px" }}  ref="playerSelect"
        defaultValue={this.props.matchValues.playerSelect}>
				<option value="playerOne">I am on Team One</option>
    <option value="playerTwo">I am on Team Two</option>
    <option value="noStats">Don't include this match in my stats</option>
			</select>
        <p>Match Venue</p>
        <div className="form-row">
        <div class="col">
          <input
            className="form-control"
            type="text"
            ref="matchVenue"
            placeholder="Match Venue"
            style={{ marginBottom: "0.8rem" }}
            defaultValue={this.props.matchValues.matchVenue}
          />
        </div>
        </div>

        <button className="btn btn-primary" onClick={this.saveAndContinue}>
          Continue
        </button>
      </div>
    );
  },



  saveAndContinue: function (e) {
    e.preventDefault();

    // Get values via this.refs
    var data = {
      teamOneLead: ReactDOM.findDOMNode(this.refs.teamOneLead).value,
      teamOneSecond: ReactDOM.findDOMNode(this.refs.teamOneSecond).value,
      teamOneThird: ReactDOM.findDOMNode(this.refs.teamOneThird).value,
      teamOneSkip: ReactDOM.findDOMNode(this.refs.teamOneSkip).value,
      teamOneName: ReactDOM.findDOMNode(this.refs.teamOneName).value,
      teamTwoLead: ReactDOM.findDOMNode(this.refs.teamTwoLead).value,
      teamTwoSecond: ReactDOM.findDOMNode(this.refs.teamTwoSecond).value,
      teamTwoThird: ReactDOM.findDOMNode(this.refs.teamTwoThird).value,
      teamTwoSkip: ReactDOM.findDOMNode(this.refs.teamTwoSkip).value,
      teamTwoName: ReactDOM.findDOMNode(this.refs.teamTwoName).value,
      playerOneName: ReactDOM.findDOMNode(this.refs.teamOneName).value,
      playerTwoName: ReactDOM.findDOMNode(this.refs.teamTwoName).value,
      matchVenue: ReactDOM.findDOMNode(this.refs.matchVenue).value,
      playerSelect: ReactDOM.findDOMNode(this.refs.playerSelect).value,
    };

    this.props.saveValues(data);
    this.props.nextStep();
  },
});

export default FoursPlayerNameFields;
