import React, { Component } from "react";
import fire from "./config/fire";
import "firebase/firestore";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
  }

  


  logOut() {
    fire.auth().signOut();
  }

componentDidMount() {

}

  render() { 
    return (
      <div>
        

        <h1>Welcome to rollUp</h1>

        <p>Thanks for testing the new version of rollUp Scorecard. We appreciate your patience while we develop the tool. Any issues, please let us know at app@rollupbowls.com.au</p>
    <p>Singles, Proportional, Pairs, Triples and Fours Matches are available now.</p>  
    <p>New feature: Match statistics have been added. The statistics will not include past matches or matches played using the iPhone/iPad app. This will be rectified shortly.</p>
    
        
        <button className="btn btn-primary" onClick={this.logOut}>Log Out</button>
      </div>
    );
  }
}

export default Welcome;
