import React from "react";
import logo from "../img/rollUpLogo.png";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import fire from "./config/fire";

import { NavLink } from "react-router-dom";




var img = "img";


const logOut = () => {
  fire.auth().signOut();
    ;
    }

const Navigation = () => {
  return (
    
    <nav class="navbar navbar-light navbar-expand-sm">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarText">
    <img class="navbar-brand" src={logo} style={{ width: "100px" }} />
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
      <a class="nav-link" href="/">Home</a>
      </li>
      <li class="nav-item">
      <a class="nav-link" href="/stats">Statistics</a>
      </li>
      <li class="nav-item">
      <a class="nav-link" href="/account">Your Account</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Saved Matches
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="nav-link" href="/Savedmatches">Singles</a>
      <a class="nav-link" href="/savedmatchesproportional">Proportional</a>
      <a class="nav-link" href="/savedmatchespairs">Pairs</a>
      <a class="nav-link" href="/savedmatchestriples">Triples</a>
      <a class="nav-link" href="/savedmatchesfours">Fours</a>
      </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Start Match
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
      <a class="nav-link" href="/Startsinglesmatch">Singles</a>
      <a class="nav-link" href="/Startproportionalmatch">Proportional</a>
      <a class="nav-link" href="/Startpairsmatch">Pairs</a>
      <a class="nav-link" href="/Starttriplesmatch">Triples</a>
      <a class="nav-link" href="/Startfoursmatch">Fours</a>
      
      </div>
      </li>
      </ul>
    </div>
    </nav>
  );
};



export default Navigation;
