import React from "react";
import firebase from "firebase";
import "firebase/firestore";




class Proportionalmatch extends React.Component {
  state = {
    playerOneScore: this.props.playerOneScoreEntry,
    existingPlayerOneScore: this.props.playerOneScoreEntry,
    playerTwoScore: this.props.playerTwoScoreEntry,
    existingPlayerTwoScore: this.props.playerTwoScoreEntry,
    playerOneScoreArray: [],
    existingPlayerOneScoreArray: [],
    playerTwoScoreArray: [],
    existingPlayerTwoScoreArray: [],
    Ends: 0,
    existingEnds: 0,
    winnerName: "",
    matchID: "",
    endTimes: [],
    existingEndTimes: [],
    playerOneEndsWon: 0,
    playerTwoEndsWon: 0,
    existingPlayerOneEndsWon: 0,
    existingPlayerTwoEndsWon: 0
  };


createMatchRecord() {

  const increment = firebase.firestore.FieldValue.increment(1);

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").add({
    playerOneName: this.props.playerOneNameEntry,
    playerTwoName: this.props.playerTwoNameEntry,
    matchVenue: this.props.matchVenueEntry,
    gameType: this.props.gameTypeEntry,
       gameEndsAt: this.props.gameEndsAtEntry,
       matchComplete: false,

    matchDate: Date()
  }).then(docRef => {
  this.setState({ matchID: docRef.id })

  if (this.props.playerSelectEntry !== "noStats")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    totalMatches: increment,
    proportionalMatches: increment,
    }, {merge:true})
   var data3 = {
       matchID: docRef.id,
   }}
   else {

   }

   var data3 = {
       matchID: docRef.id,
   }

   this.props.saveValues(data3);
      
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(docRef.id).set({
matchID: docRef.id
    }, {merge: true})
}).catch((error) => {
  console.log(error);
});

}


  undoButton = () => {
//set State to previous score
    this.setState({ 
  playerOneScore: this.state.existingPlayerOneScore,
  playerTwoScore: this.state.existingPlayerTwoScore,
  playerOneScoreArray: this.state.existingPlayerOneScoreArray,
  playerTwoScoreArray: this.state.existingPlayerTwoScoreArray,
  endTimes: this.state.existingEndTimes,
  Ends: this.state.existingEnds,
  playerOneEndsWon: this.state.existingPlayerOneEndsWon,
  playerTwoEndsWon: this.state.existingPlayerTwoEndsWon
})
//Update Firestore
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
  playerOneScore: this.state.existingPlayerOneScore,
  playerTwoScore: this.state.existingPlayerTwoScore,
  endNumber: this.state.existingEnds,
  playerOneEndScores: this.state.existingPlayerOneScoreArray,
  playerTwoEndScores: this.state.existingPlayerTwoScoreArray,
  endTimes: this.state.existingEndTimes,
  playerOneEndsWon: this.state.existingPlayerOneEndsWon,
  playerTwoEndsWon: this.state.existingPlayerTwoEndsWon
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });


  }

  playerOneTenZero = () => {
    // Set the existing values as current values before we change anything in case we have to reset.

    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

    // Now update the scores, ends and ends player each one. 

    this.setState({ playerOneScore: this.state.playerOneScore + 10 });
    this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });

    // Now update the arrays - scores so far and end times


    var newArray = this.state.playerOneScoreArray.concat(10);
    var otherNewArray = this.state.playerTwoScoreArray.concat(0); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })

    //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore + 10,
playerTwoScore: this.state.playerTwoScore,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore + 10 >= this.props.gameEndsAtEntry) 
  {
    console.log('Player One Wins')
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

      // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 10);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          console.log('Player One Wins on points Data Added')
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{
  console.log('Player Two Wins on Points Data Added')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}




         else {
          console.log('No one Selected Data Added' + this.props.playerSelectEntry)
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 10,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 



}

// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

    if (this.state.playerOneScore + 10 > this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });

           // Set the increments for overall stats

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 10);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

      // Now if the logged in Player is Player One, update their overall stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          console.log('Player One Wins on ends Data Added')
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement,
          proportionalPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }

        // If the logged in player is Player Two, updated their stats. 



        if (this.props.playerSelectEntry === "playerTwo")
{
  console.log('Player One Wins on ends Data Added - Player two logged in')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
}


         else {
          console.log('No winner on ends no data added')
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 10,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
this.props.saveValues(data);
 
      }
        

         // Now if Player Two's score his higher


    if (this.state.playerOneScore + 10 < this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments

   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 10);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

   // And if the Logged in player is Player Two 

   if (this.props.playerSelectEntry === "playerTwo")
     
     {
      console.log('Player Two Wins on ends Data Added - Player two logged in')
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       proportionalMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       proportionalEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       proportionalEndsWon: playerTwoEndsWonIncrement,
       proportionalPointsAgainst: playerOnePointsWonIncrement,
       proportionalPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }

     // and if the logged in player is Player One (and they've lost)

     if (this.props.playerSelectEntry === "playerOne")
{
  console.log('Player Two Wins on ends Data Added - Player One logged in')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
 endsPlayed: EndsPlayedIncrement,
 proportionalEndsPlayed: EndsPlayedIncrement,
 endsWon: playerOneEndsWonIncrement,
 proportionalEndsWon: playerOneEndsWonIncrement,
 proportionalPointsAgainst: playerTwoPointsWonIncrement,
 proportionalPointsWon: playerOnePointsWonIncrement
 }, {merge:true})
}


      else {
        console.log('No one logged in')
      }



      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore  + 10,
    playerTwoScore: this.state.playerTwoScore,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }

// Finally, if scores are level. 


    if (this.state.playerOneScore + 10 === this.state.playerTwoScore) {
      this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 10);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    console.log('Draw player one logged in')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{
  console.log('Draw player two logged in')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 10,
playerTwoScore: this.state.playerTwoScore,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

    }

  }
}


  };


// NINE ONE



playerOneNineOne = () => {
  // Set the existing values as current values before we change anything in case we have to reset.

  this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
  this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
  this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

  // Now update the scores, ends and ends player each one. 

  this.setState({ playerOneScore: this.state.playerOneScore + 9 });
  this.setState({ playerTwoScore: this.state.playerTwoScore + 1 });
  this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
  this.setState({ Ends: this.state.Ends + 1 });

  // Now update the arrays - scores so far and end times


  var newArray = this.state.playerOneScoreArray.concat(9);
  var otherNewArray = this.state.playerTwoScoreArray.concat(1); 
  var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
  this.setState({ playerOneScoreArray: newArray })
  this.setState({ playerTwoScoreArray: otherNewArray })
  this.setState({ endTimes: timeArray })

  //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore + 9,
playerTwoScore: this.state.playerTwoScore + 1,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore + 9 >= this.props.gameEndsAtEntry || this.state.playerTwoScore + 1 >= this.props.gameEndsAtEntry) 
  {
    if (this.state.playerOneScore + 9 > this.state.playerTwoScore + 1)
    {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
     // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 9);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 1);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}

         else {
      // Do nothing if there are no stats for this game.
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 9,
      playerTwoScore: this.state.playerTwoScore + 1,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 

  // If player two's score is higher

if (this.state.playerOneScore + 9 < this.state.playerTwoScore + 1)
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
    

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 1);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 9);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// And if the Logged in player is Player Two 

if (this.props.playerSelectEntry === "playerTwo")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    proportionalMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon:playerTwoPointsWonIncrement
    }, {merge:true})
  }

  // and if the logged in player is Player One (and they've lost)

  if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


   else {
// do nothing if this match is excluded from stats. 
   }



   data = {
     winnerName: this.props.playerTwoNameEntry,
     playerOneScore: this.state.playerOneScore  + 9,
 playerTwoScore: this.state.playerTwoScore + 1,
 playerOneEndsWon: this.state.playerOneEndsWon + 1,
 playerTwoEndsWon: this.state.playerTwoEndsWon,
 ends: this.state.Ends + 1,
   };
   this.props.nextStep();
this.props.saveValues(data);

 }

// And if scores are equal

  if (this.state.playerOneScore + 9 === this.state.playerTwoScore + 1)
  {
    console.log('Match Drawn')
    this.setState({ winnerName: "Match Drawn" })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: "Match Drawn",
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 1);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 9);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}

data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 9,
playerTwoScore: this.state.playerTwoScore + 1,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

  }
  }


// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
{

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

  if (this.state.playerOneScore + 9 > this.state.playerTwoScore + 1) {
    this.setState({ winnerName: this.props.playerOneNameEntry });
    console.log('Player One Wins')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

         // Set the increments for overall stats

    const increment = firebase.firestore.FieldValue.increment(1);
    const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
    const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 9);
    const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 1); 
    const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
    const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

    // Now if the logged in Player is Player One, update their overall stats. 

    if (this.props.playerSelectEntry === "playerOne")
      
      {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
        matchesWon: increment,
        proportionalMatchesWon: increment,
        endsPlayed: EndsPlayedIncrement,
        proportionalEndsPlayed: EndsPlayedIncrement,
        endsWon: playerOneEndsWonIncrement,
        proportionalEndsWon: playerOneEndsWonIncrement,
        proportionalPointsAgainst: playerTwoPointsWonIncrement,
        proportionalPointsWon: playerOnePointsWonIncrement
        }, {merge:true})
      }

      // If the logged in player is Player Two, updated their stats. 



      if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  proportionalEndsWon: playerTwoEndsWonIncrement,
  proportionalPointsAgainst: playerOnePointsWonIncrement,
  proportionalPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
}


       else {
    // Do nothing if this match is excluded from stats
       }
      
      
      
       data = {
        winnerName: this.props.playerOneNameEntry,
        playerOneScore: this.state.playerOneScore  + 9,
    playerTwoScore: this.state.playerTwoScore + 1,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }
      

       // Now if Player Two's score his higher


  if (this.state.playerOneScore + 9 < this.state.playerTwoScore + 1) {
    this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: this.props.playerTwoNameEntry,
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

 // Set the increments

 const increment = firebase.firestore.FieldValue.increment(1);
 const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
 const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
 const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 1);
 const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 9);
 const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

 // And if the Logged in player is Player Two 

 if (this.props.playerSelectEntry === "playerTwo")
   
   {
     firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
     matchesWon: increment,
     proportionalMatchesWon: increment,
     endsPlayed: EndsPlayedIncrement,
     proportionalEndsPlayed: EndsPlayedIncrement,
     endsWon: playerTwoEndsWonIncrement,
     proportionalEndsWon: playerTwoEndsWonIncrement,
     proportionalPointsAgainst: playerOnePointsWonIncrement,
     proportionalPointsWon:playerTwoPointsWonIncrement
     }, {merge:true})
   }

   // and if the logged in player is Player One (and they've lost)

   if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


    else {
 // do nothing if this match is excluded from stats. 
    }



    data = {
      winnerName: this.props.playerTwoNameEntry,
      playerOneScore: this.state.playerOneScore  + 9,
  playerTwoScore: this.state.playerTwoScore + 1,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
    };
    this.props.nextStep();
this.props.saveValues(data);

  }

// Finally, if scores are level. 


  if (this.state.playerOneScore + 9 === this.state.playerTwoScore + 1) {
    this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: "Match Drawn",
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 1);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 9);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")

{
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  proportionalEndsWon: playerOneEndsWonIncrement,
  proportionalPointsAgainst: playerTwoPointsWonIncrement,
  proportionalPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
}
// and finally if the logged in player is player two 

if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
matchesDrawn: increment,
proportionalMatchesDrawn: increment,
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
winnerName: "Match Drawn",
playerOneScore: this.state.playerOneScore  + 9,
playerTwoScore: this.state.playerTwoScore + 1,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);
  }
  }
}
}

};

playerOneEightTwo = () => {
  // Set the existing values as current values before we change anything in case we have to reset.

  this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
  this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
  this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

  // Now update the scores, ends and ends player each one. 

  this.setState({ playerOneScore: this.state.playerOneScore + 8 });
  this.setState({ playerTwoScore: this.state.playerTwoScore + 2 });
  this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
  this.setState({ Ends: this.state.Ends + 1 });

  // Now update the arrays - scores so far and end times


  var newArray = this.state.playerOneScoreArray.concat(8);
  var otherNewArray = this.state.playerTwoScoreArray.concat(2); 
  var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
  this.setState({ playerOneScoreArray: newArray })
  this.setState({ playerTwoScoreArray: otherNewArray })
  this.setState({ endTimes: timeArray })

  //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore + 8,
playerTwoScore: this.state.playerTwoScore + 2,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore + 8 >= this.props.gameEndsAtEntry || this.state.playerTwoScore + 2 >= this.props.gameEndsAtEntry) 
  {
    if (this.state.playerOneScore + 8 > this.state.playerTwoScore + 2)
    {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
     // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 8);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 2);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}

         else {
      // Do nothing if there are no stats for this game.
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 8,
      playerTwoScore: this.state.playerTwoScore + 2,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 

  // If player two's score is higher

if (this.state.playerOneScore + 8 < this.state.playerTwoScore + 2)
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
    

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 2);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 8);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// And if the Logged in player is Player Two 

if (this.props.playerSelectEntry === "playerTwo")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    proportionalMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon:playerTwoPointsWonIncrement
    }, {merge:true})
  }

  // and if the logged in player is Player One (and they've lost)

  if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


   else {
// do nothing if this match is excluded from stats. 
   }



   data = {
     winnerName: this.props.playerTwoNameEntry,
     playerOneScore: this.state.playerOneScore  + 8,
 playerTwoScore: this.state.playerTwoScore + 2,
 playerOneEndsWon: this.state.playerOneEndsWon + 1,
 playerTwoEndsWon: this.state.playerTwoEndsWon,
 ends: this.state.Ends + 1,
   };
   this.props.nextStep();
this.props.saveValues(data);

 }

// And if scores are equal

  if (this.state.playerOneScore + 8 === this.state.playerTwoScore + 2)
  {
    console.log('Match Drawn')
    this.setState({ winnerName: "Match Drawn" })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: "Match Drawn",
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 2);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 8);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}

data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 8,
playerTwoScore: this.state.playerTwoScore + 2,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

  }
  }


// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
{

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

  if (this.state.playerOneScore + 8 > this.state.playerTwoScore + 2) {
    this.setState({ winnerName: this.props.playerOneNameEntry });
    console.log('Player One Wins')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

         // Set the increments for overall stats

    const increment = firebase.firestore.FieldValue.increment(1);
    const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
    const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 8);
    const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 2); 
    const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
    const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

    // Now if the logged in Player is Player One, update their overall stats. 

    if (this.props.playerSelectEntry === "playerOne")
      
      {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
        matchesWon: increment,
        proportionalMatchesWon: increment,
        endsPlayed: EndsPlayedIncrement,
        proportionalEndsPlayed: EndsPlayedIncrement,
        endsWon: playerOneEndsWonIncrement,
        proportionalEndsWon: playerOneEndsWonIncrement,
        proportionalPointsAgainst: playerTwoPointsWonIncrement,
        proportionalPointsWon: playerOnePointsWonIncrement
        }, {merge:true})
      }

      // If the logged in player is Player Two, updated their stats. 



      if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  proportionalEndsWon: playerTwoEndsWonIncrement,
  proportionalPointsAgainst: playerOnePointsWonIncrement,
  proportionalPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
}


       else {
    // Do nothing if this match is excluded from stats
       }
      
      
      
       data = {
        winnerName: this.props.playerOneNameEntry,
        playerOneScore: this.state.playerOneScore  + 8,
    playerTwoScore: this.state.playerTwoScore + 2,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }
      

       // Now if Player Two's score his higher


  if (this.state.playerOneScore + 8 < this.state.playerTwoScore + 2) {
    this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: this.props.playerTwoNameEntry,
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

 // Set the increments

 const increment = firebase.firestore.FieldValue.increment(1);
 const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
 const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
 const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 2);
 const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 8);
 const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

 // And if the Logged in player is Player Two 

 if (this.props.playerSelectEntry === "playerTwo")
   
   {
     firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
     matchesWon: increment,
     proportionalMatchesWon: increment,
     endsPlayed: EndsPlayedIncrement,
     proportionalEndsPlayed: EndsPlayedIncrement,
     endsWon: playerTwoEndsWonIncrement,
     proportionalEndsWon: playerTwoEndsWonIncrement,
     proportionalPointsAgainst: playerOnePointsWonIncrement,
     proportionalPointsWon:playerTwoPointsWonIncrement
     }, {merge:true})
   }

   // and if the logged in player is Player One (and they've lost)

   if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


    else {
 // do nothing if this match is excluded from stats. 
    }



    data = {
      winnerName: this.props.playerTwoNameEntry,
      playerOneScore: this.state.playerOneScore  + 8,
  playerTwoScore: this.state.playerTwoScore + 2,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
    };
    this.props.nextStep();
this.props.saveValues(data);

  }

// Finally, if scores are level. 


  if (this.state.playerOneScore + 8 === this.state.playerTwoScore + 2) {
    this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: "Match Drawn",
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 2);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 8);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")

{
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  proportionalEndsWon: playerOneEndsWonIncrement,
  proportionalPointsAgainst: playerTwoPointsWonIncrement,
  proportionalPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
}
// and finally if the logged in player is player two 

if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
matchesDrawn: increment,
proportionalMatchesDrawn: increment,
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
winnerName: "Match Drawn",
playerOneScore: this.state.playerOneScore  + 8,
playerTwoScore: this.state.playerTwoScore + 2,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);
  }
  }
}
}

};


playerOneSevenThree = () => {
  // Set the existing values as current values before we change anything in case we have to reset.

  this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
  this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
  this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

  // Now update the scores, ends and ends player each one. 

  this.setState({ playerOneScore: this.state.playerOneScore + 7 });
  this.setState({ playerTwoScore: this.state.playerTwoScore + 3 });
  this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
  this.setState({ Ends: this.state.Ends + 1 });

  // Now update the arrays - scores so far and end times


  var newArray = this.state.playerOneScoreArray.concat(7);
  var otherNewArray = this.state.playerTwoScoreArray.concat(3); 
  var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
  this.setState({ playerOneScoreArray: newArray })
  this.setState({ playerTwoScoreArray: otherNewArray })
  this.setState({ endTimes: timeArray })

  //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore + 7,
playerTwoScore: this.state.playerTwoScore + 3,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore + 7 >= this.props.gameEndsAtEntry || this.state.playerTwoScore + 3 >= this.props.gameEndsAtEntry) 
  {
    if (this.state.playerOneScore + 7 > this.state.playerTwoScore + 3)
    {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
     // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 7);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 3);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}

         else {
      // Do nothing if there are no stats for this game.
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 7,
      playerTwoScore: this.state.playerTwoScore + 3,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 

  // If player two's score is higher

if (this.state.playerOneScore + 7 < this.state.playerTwoScore + 3)
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
    

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 3);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 7);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// And if the Logged in player is Player Two 

if (this.props.playerSelectEntry === "playerTwo")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    proportionalMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon:playerTwoPointsWonIncrement
    }, {merge:true})
  }

  // and if the logged in player is Player One (and they've lost)

  if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


   else {
// do nothing if this match is excluded from stats. 
   }



   data = {
     winnerName: this.props.playerTwoNameEntry,
     playerOneScore: this.state.playerOneScore  + 7,
 playerTwoScore: this.state.playerTwoScore + 3,
 playerOneEndsWon: this.state.playerOneEndsWon + 1,
 playerTwoEndsWon: this.state.playerTwoEndsWon,
 ends: this.state.Ends + 1,
   };
   this.props.nextStep();
this.props.saveValues(data);

 }

// And if scores are equal

  if (this.state.playerOneScore + 7 === this.state.playerTwoScore + 3)
  {
    console.log('Match Drawn')
    this.setState({ winnerName: "Match Drawn" })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: "Match Drawn",
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 3);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 7);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}

data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 7,
playerTwoScore: this.state.playerTwoScore + 3,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

  }
  }


// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
{

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

  if (this.state.playerOneScore + 7 > this.state.playerTwoScore + 3) {
    this.setState({ winnerName: this.props.playerOneNameEntry });
    console.log('Player One Wins')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

         // Set the increments for overall stats

    const increment = firebase.firestore.FieldValue.increment(1);
    const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
    const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 7);
    const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 3); 
    const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
    const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

    // Now if the logged in Player is Player One, update their overall stats. 

    if (this.props.playerSelectEntry === "playerOne")
      
      {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
        matchesWon: increment,
        proportionalMatchesWon: increment,
        endsPlayed: EndsPlayedIncrement,
        proportionalEndsPlayed: EndsPlayedIncrement,
        endsWon: playerOneEndsWonIncrement,
        proportionalEndsWon: playerOneEndsWonIncrement,
        proportionalPointsAgainst: playerTwoPointsWonIncrement,
        proportionalPointsWon: playerOnePointsWonIncrement
        }, {merge:true})
      }

      // If the logged in player is Player Two, updated their stats. 



      if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  proportionalEndsWon: playerTwoEndsWonIncrement,
  proportionalPointsAgainst: playerOnePointsWonIncrement,
  proportionalPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
}


       else {
    // Do nothing if this match is excluded from stats
       }
      
      
      
       data = {
        winnerName: this.props.playerOneNameEntry,
        playerOneScore: this.state.playerOneScore  + 7,
    playerTwoScore: this.state.playerTwoScore + 3,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }
      

       // Now if Player Two's score his higher


  if (this.state.playerOneScore + 7 < this.state.playerTwoScore + 3) {
    this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: this.props.playerTwoNameEntry,
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

 // Set the increments

 const increment = firebase.firestore.FieldValue.increment(1);
 const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
 const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
 const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 3);
 const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 7);
 const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

 // And if the Logged in player is Player Two 

 if (this.props.playerSelectEntry === "playerTwo")
   
   {
     firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
     matchesWon: increment,
     proportionalMatchesWon: increment,
     endsPlayed: EndsPlayedIncrement,
     proportionalEndsPlayed: EndsPlayedIncrement,
     endsWon: playerTwoEndsWonIncrement,
     proportionalEndsWon: playerTwoEndsWonIncrement,
     proportionalPointsAgainst: playerOnePointsWonIncrement,
     proportionalPointsWon:playerTwoPointsWonIncrement
     }, {merge:true})
   }

   // and if the logged in player is Player One (and they've lost)

   if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


    else {
 // do nothing if this match is excluded from stats. 
    }



    data = {
      winnerName: this.props.playerTwoNameEntry,
      playerOneScore: this.state.playerOneScore  + 7,
  playerTwoScore: this.state.playerTwoScore + 3,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
    };
    this.props.nextStep();
this.props.saveValues(data);

  }

// Finally, if scores are level. 


  if (this.state.playerOneScore + 7 === this.state.playerTwoScore + 3) {
    this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: "Match Drawn",
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 3);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 7);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")

{
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  proportionalEndsWon: playerOneEndsWonIncrement,
  proportionalPointsAgainst: playerTwoPointsWonIncrement,
  proportionalPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
}
// and finally if the logged in player is player two 

if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
matchesDrawn: increment,
proportionalMatchesDrawn: increment,
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
winnerName: "Match Drawn",
playerOneScore: this.state.playerOneScore  + 7,
playerTwoScore: this.state.playerTwoScore + 3,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);
  }
  }
}
}

};

playerOneSixFour = () => {
  // Set the existing values as current values before we change anything in case we have to reset.

  this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
  this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
  this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

  // Now update the scores, ends and ends player each one. 

  this.setState({ playerOneScore: this.state.playerOneScore + 6 });
  this.setState({ playerTwoScore: this.state.playerTwoScore + 4 });
  this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
  this.setState({ Ends: this.state.Ends + 1 });

  // Now update the arrays - scores so far and end times


  var newArray = this.state.playerOneScoreArray.concat(6);
  var otherNewArray = this.state.playerTwoScoreArray.concat(4); 
  var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
  this.setState({ playerOneScoreArray: newArray })
  this.setState({ playerTwoScoreArray: otherNewArray })
  this.setState({ endTimes: timeArray })

  //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore + 6,
playerTwoScore: this.state.playerTwoScore + 4,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore + 6 >= this.props.gameEndsAtEntry || this.state.playerTwoScore + 4 >= this.props.gameEndsAtEntry) 
  {
    if (this.state.playerOneScore + 6 > this.state.playerTwoScore + 4)
    {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
     // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 6);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 4);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}

         else {
      // Do nothing if there are no stats for this game.
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 6,
      playerTwoScore: this.state.playerTwoScore + 4,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 

  // If player two's score is higher

if (this.state.playerOneScore + 6 < this.state.playerTwoScore + 4)
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
    

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 4);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 6);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// And if the Logged in player is Player Two 

if (this.props.playerSelectEntry === "playerTwo")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    proportionalMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon:playerTwoPointsWonIncrement
    }, {merge:true})
  }

  // and if the logged in player is Player One (and they've lost)

  if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


   else {
// do nothing if this match is excluded from stats. 
   }



   data = {
     winnerName: this.props.playerTwoNameEntry,
     playerOneScore: this.state.playerOneScore  + 6,
 playerTwoScore: this.state.playerTwoScore + 4,
 playerOneEndsWon: this.state.playerOneEndsWon + 1,
 playerTwoEndsWon: this.state.playerTwoEndsWon,
 ends: this.state.Ends + 1,
   };
   this.props.nextStep();
this.props.saveValues(data);

 }

// And if scores are equal

  if (this.state.playerOneScore + 6 === this.state.playerTwoScore + 4)
  {
    console.log('Match Drawn')
    this.setState({ winnerName: "Match Drawn" })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: "Match Drawn",
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 4);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 6);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}

data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 6,
playerTwoScore: this.state.playerTwoScore + 4,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

  }
  }


// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
{

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

  if (this.state.playerOneScore + 6 > this.state.playerTwoScore + 4) {
    this.setState({ winnerName: this.props.playerOneNameEntry });
    console.log('Player One Wins')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

         // Set the increments for overall stats

    const increment = firebase.firestore.FieldValue.increment(1);
    const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
    const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 6);
    const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 4); 
    const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
    const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

    // Now if the logged in Player is Player One, update their overall stats. 

    if (this.props.playerSelectEntry === "playerOne")
      
      {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
        matchesWon: increment,
        proportionalMatchesWon: increment,
        endsPlayed: EndsPlayedIncrement,
        proportionalEndsPlayed: EndsPlayedIncrement,
        endsWon: playerOneEndsWonIncrement,
        proportionalEndsWon: playerOneEndsWonIncrement,
        proportionalPointsAgainst: playerTwoPointsWonIncrement,
        proportionalPointsWon: playerOnePointsWonIncrement
        }, {merge:true})
      }

      // If the logged in player is Player Two, updated their stats. 



      if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  proportionalEndsWon: playerTwoEndsWonIncrement,
  proportionalPointsAgainst: playerOnePointsWonIncrement,
  proportionalPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
}


       else {
    // Do nothing if this match is excluded from stats
       }
      
      
      
       data = {
        winnerName: this.props.playerOneNameEntry,
        playerOneScore: this.state.playerOneScore  + 6,
    playerTwoScore: this.state.playerTwoScore + 4,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }
      

       // Now if Player Two's score his higher


  if (this.state.playerOneScore + 6 < this.state.playerTwoScore + 4) {
    this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: this.props.playerTwoNameEntry,
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

 // Set the increments

 const increment = firebase.firestore.FieldValue.increment(1);
 const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
 const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
 const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 4);
 const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 6);
 const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

 // And if the Logged in player is Player Two 

 if (this.props.playerSelectEntry === "playerTwo")
   
   {
     firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
     matchesWon: increment,
     proportionalMatchesWon: increment,
     endsPlayed: EndsPlayedIncrement,
     proportionalEndsPlayed: EndsPlayedIncrement,
     endsWon: playerTwoEndsWonIncrement,
     proportionalEndsWon: playerTwoEndsWonIncrement,
     proportionalPointsAgainst: playerOnePointsWonIncrement,
     proportionalPointsWon:playerTwoPointsWonIncrement
     }, {merge:true})
   }

   // and if the logged in player is Player One (and they've lost)

   if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


    else {
 // do nothing if this match is excluded from stats. 
    }



    data = {
      winnerName: this.props.playerTwoNameEntry,
      playerOneScore: this.state.playerOneScore  + 6,
  playerTwoScore: this.state.playerTwoScore + 4,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
    };
    this.props.nextStep();
this.props.saveValues(data);

  }

// Finally, if scores are level. 


  if (this.state.playerOneScore + 6 === this.state.playerTwoScore + 4) {
    this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: "Match Drawn",
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 4);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 6);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")

{
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  proportionalEndsWon: playerOneEndsWonIncrement,
  proportionalPointsAgainst: playerTwoPointsWonIncrement,
  proportionalPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
}
// and finally if the logged in player is player two 

if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
matchesDrawn: increment,
proportionalMatchesDrawn: increment,
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
winnerName: "Match Drawn",
playerOneScore: this.state.playerOneScore  + 6,
playerTwoScore: this.state.playerTwoScore + 4,
playerOneEndsWon: this.state.playerOneEndsWon + 1,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);
  }
  }
}
}

};

fiveFive = () => {
  // Set the existing values as current values before we change anything in case we have to reset.

  this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
  this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
  this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

  // Now update the scores, ends and ends player each one. 

  this.setState({ playerOneScore: this.state.playerOneScore + 5 });
  this.setState({ playerTwoScore: this.state.playerTwoScore + 5 });
  this.setState({ Ends: this.state.Ends + 1 });

  // Now update the arrays - scores so far and end times


  var newArray = this.state.playerOneScoreArray.concat(5);
  var otherNewArray = this.state.playerTwoScoreArray.concat(5); 
  var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
  this.setState({ playerOneScoreArray: newArray })
  this.setState({ playerTwoScoreArray: otherNewArray })
  this.setState({ endTimes: timeArray })

  //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore + 5,
playerTwoScore: this.state.playerTwoScore + 5,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore + 5 >= this.props.gameEndsAtEntry || this.state.playerTwoScore + 5 >= this.props.gameEndsAtEntry) 
  {
    if (this.state.playerOneScore + 5 > this.state.playerTwoScore + 5)
    {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
     // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 5);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 5);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}

         else {
      // Do nothing if there are no stats for this game.
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 5,
      playerTwoScore: this.state.playerTwoScore + 5,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 

  // If player two's score is higher

if (this.state.playerOneScore + 5 < this.state.playerTwoScore + 5)
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
    

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 5);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 5);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// And if the Logged in player is Player Two 

if (this.props.playerSelectEntry === "playerTwo")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    proportionalMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon:playerTwoPointsWonIncrement
    }, {merge:true})
  }

  // and if the logged in player is Player One (and they've lost)

  if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


   else {
// do nothing if this match is excluded from stats. 
   }



   data = {
     winnerName: this.props.playerTwoNameEntry,
     playerOneScore: this.state.playerOneScore  + 5,
 playerTwoScore: this.state.playerTwoScore + 5,
 playerOneEndsWon: this.state.playerOneEndsWon,
 playerTwoEndsWon: this.state.playerTwoEndsWon,
 ends: this.state.Ends + 1,
   };
   this.props.nextStep();
this.props.saveValues(data);

 }

// And if scores are equal

  if (this.state.playerOneScore + 5 === this.state.playerTwoScore + 5)
  {
    console.log('Match Drawn')
    this.setState({ winnerName: "Match Drawn" })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: "Match Drawn",
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 5);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 5);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}

data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 5,
playerTwoScore: this.state.playerTwoScore + 5,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

  }
  }


// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
{

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

  if (this.state.playerOneScore + 5 > this.state.playerTwoScore + 5) {
    this.setState({ winnerName: this.props.playerOneNameEntry });
    console.log('Player One Wins')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

         // Set the increments for overall stats

    const increment = firebase.firestore.FieldValue.increment(1);
    const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
    const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 5);
    const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 5); 
    const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
    const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

    // Now if the logged in Player is Player One, update their overall stats. 

    if (this.props.playerSelectEntry === "playerOne")
      
      {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
        matchesWon: increment,
        proportionalMatchesWon: increment,
        endsPlayed: EndsPlayedIncrement,
        proportionalEndsPlayed: EndsPlayedIncrement,
        endsWon: playerOneEndsWonIncrement,
        proportionalEndsWon: playerOneEndsWonIncrement,
        proportionalPointsAgainst: playerTwoPointsWonIncrement,
        proportionalPointsWon: playerOnePointsWonIncrement
        }, {merge:true})
      }

      // If the logged in player is Player Two, updated their stats. 



      if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  proportionalEndsWon: playerTwoEndsWonIncrement,
  proportionalPointsAgainst: playerOnePointsWonIncrement,
  proportionalPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
}


       else {
    // Do nothing if this match is excluded from stats
       }
      
      
      
       data = {
        winnerName: this.props.playerOneNameEntry,
        playerOneScore: this.state.playerOneScore  + 5,
    playerTwoScore: this.state.playerTwoScore + 5,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }
      

       // Now if Player Two's score his higher


  if (this.state.playerOneScore + 5 < this.state.playerTwoScore + 5) {
    this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: this.props.playerTwoNameEntry,
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

 // Set the increments

 const increment = firebase.firestore.FieldValue.increment(1);
 const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
 const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
 const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 5);
 const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 5);
 const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

 // And if the Logged in player is Player Two 

 if (this.props.playerSelectEntry === "playerTwo")
   
   {
     firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
     matchesWon: increment,
     proportionalMatchesWon: increment,
     endsPlayed: EndsPlayedIncrement,
     proportionalEndsPlayed: EndsPlayedIncrement,
     endsWon: playerTwoEndsWonIncrement,
     proportionalEndsWon: playerTwoEndsWonIncrement,
     proportionalPointsAgainst: playerOnePointsWonIncrement,
     proportionalPointsWon:playerTwoPointsWonIncrement
     }, {merge:true})
   }

   // and if the logged in player is Player One (and they've lost)

   if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


    else {
 // do nothing if this match is excluded from stats. 
    }



    data = {
      winnerName: this.props.playerTwoNameEntry,
      playerOneScore: this.state.playerOneScore  + 5,
  playerTwoScore: this.state.playerTwoScore + 5,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
    };
    this.props.nextStep();
this.props.saveValues(data);

  }

// Finally, if scores are level. 


  if (this.state.playerOneScore + 5 === this.state.playerTwoScore + 5) {
    this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: "Match Drawn",
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 5);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 5);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")

{
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  proportionalEndsWon: playerOneEndsWonIncrement,
  proportionalPointsAgainst: playerTwoPointsWonIncrement,
  proportionalPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
}
// and finally if the logged in player is player two 

if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
matchesDrawn: increment,
proportionalMatchesDrawn: increment,
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
winnerName: "Match Drawn",
playerOneScore: this.state.playerOneScore  + 5,
playerTwoScore: this.state.playerTwoScore + 5,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);
  }
  }
}
}

};

playerOneFourSix = () => {
  // Set the existing values as current values before we change anything in case we have to reset.

  this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
  this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
  this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

  // Now update the scores, ends and ends player each one. 

  this.setState({ playerOneScore: this.state.playerOneScore + 4 });
  this.setState({ playerTwoScore: this.state.playerTwoScore + 6 });
  this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
  this.setState({ Ends: this.state.Ends + 1 });

  // Now update the arrays - scores so far and end times


  var newArray = this.state.playerOneScoreArray.concat(4);
  var otherNewArray = this.state.playerTwoScoreArray.concat(6); 
  var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
  this.setState({ playerOneScoreArray: newArray })
  this.setState({ playerTwoScoreArray: otherNewArray })
  this.setState({ endTimes: timeArray })

  //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore + 4,
playerTwoScore: this.state.playerTwoScore + 6,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore + 4 >= this.props.gameEndsAtEntry || this.state.playerTwoScore + 6 >= this.props.gameEndsAtEntry) 
  {
    if (this.state.playerOneScore + 4 > this.state.playerTwoScore + 6)
    {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
     // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 4);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 6);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}

         else {
      // Do nothing if there are no stats for this game.
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 4,
      playerTwoScore: this.state.playerTwoScore + 6,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 

  // If player two's score is higher

if (this.state.playerOneScore + 4 < this.state.playerTwoScore + 6)
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
    

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 6);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 4);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// And if the Logged in player is Player Two 

if (this.props.playerSelectEntry === "playerTwo")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    proportionalMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon:playerTwoPointsWonIncrement
    }, {merge:true})
  }

  // and if the logged in player is Player One (and they've lost)

  if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


   else {
// do nothing if this match is excluded from stats. 
   }



   data = {
     winnerName: this.props.playerTwoNameEntry,
     playerOneScore: this.state.playerOneScore  + 4,
 playerTwoScore: this.state.playerTwoScore + 6,
 playerOneEndsWon: this.state.playerOneEndsWon,
 playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
 ends: this.state.Ends + 1,
   };
   this.props.nextStep();
this.props.saveValues(data);

 }

// And if scores are equal

  if (this.state.playerOneScore + 4 === this.state.playerTwoScore + 6)
  {
    console.log('Match Drawn')
    this.setState({ winnerName: "Match Drawn" })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: "Match Drawn",
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 6);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 4);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}

data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 4,
playerTwoScore: this.state.playerTwoScore + 6,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

  }
  }


// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
{

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

  if (this.state.playerOneScore + 4 > this.state.playerTwoScore + 6) {
    this.setState({ winnerName: this.props.playerOneNameEntry });
    console.log('Player One Wins')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

         // Set the increments for overall stats

    const increment = firebase.firestore.FieldValue.increment(1);
    const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
    const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 4);
    const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 6); 
    const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
    const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

    // Now if the logged in Player is Player One, update their overall stats. 

    if (this.props.playerSelectEntry === "playerOne")
      
      {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
        matchesWon: increment,
        proportionalMatchesWon: increment,
        endsPlayed: EndsPlayedIncrement,
        proportionalEndsPlayed: EndsPlayedIncrement,
        endsWon: playerOneEndsWonIncrement,
        proportionalEndsWon: playerOneEndsWonIncrement,
        proportionalPointsAgainst: playerTwoPointsWonIncrement,
        proportionalPointsWon: playerOnePointsWonIncrement
        }, {merge:true})
      }

      // If the logged in player is Player Two, updated their stats. 



      if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  proportionalEndsWon: playerTwoEndsWonIncrement,
  proportionalPointsAgainst: playerOnePointsWonIncrement,
  proportionalPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
}


       else {
    // Do nothing if this match is excluded from stats
       }
      
      
      
       data = {
        winnerName: this.props.playerOneNameEntry,
        playerOneScore: this.state.playerOneScore  + 4,
    playerTwoScore: this.state.playerTwoScore + 6,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }
      

       // Now if Player Two's score his higher


  if (this.state.playerOneScore + 4 < this.state.playerTwoScore + 6) {
    this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: this.props.playerTwoNameEntry,
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

 // Set the increments

 const increment = firebase.firestore.FieldValue.increment(1);
 const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
 const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
 const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 6);
 const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 4);
 const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

 // And if the Logged in player is Player Two 

 if (this.props.playerSelectEntry === "playerTwo")
   
   {
     firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
     matchesWon: increment,
     proportionalMatchesWon: increment,
     endsPlayed: EndsPlayedIncrement,
     proportionalEndsPlayed: EndsPlayedIncrement,
     endsWon: playerTwoEndsWonIncrement,
     proportionalEndsWon: playerTwoEndsWonIncrement,
     proportionalPointsAgainst: playerOnePointsWonIncrement,
     proportionalPointsWon:playerTwoPointsWonIncrement
     }, {merge:true})
   }

   // and if the logged in player is Player One (and they've lost)

   if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


    else {
 // do nothing if this match is excluded from stats. 
    }



    data = {
      winnerName: this.props.playerTwoNameEntry,
      playerOneScore: this.state.playerOneScore  + 4,
  playerTwoScore: this.state.playerTwoScore + 6,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
    };
    this.props.nextStep();
this.props.saveValues(data);

  }

// Finally, if scores are level. 


  if (this.state.playerOneScore + 4 === this.state.playerTwoScore + 6) {
    this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: "Match Drawn",
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 6);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 4);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")

{
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  proportionalEndsWon: playerOneEndsWonIncrement,
  proportionalPointsAgainst: playerTwoPointsWonIncrement,
  proportionalPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
}
// and finally if the logged in player is player two 

if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
matchesDrawn: increment,
proportionalMatchesDrawn: increment,
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
winnerName: "Match Drawn",
playerOneScore: this.state.playerOneScore  + 4,
playerTwoScore: this.state.playerTwoScore + 6,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);
  }
  }
}
}

};

playerOneThreeSeven = () => {
  // Set the existing values as current values before we change anything in case we have to reset.

  this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
  this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
  this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

  // Now update the scores, ends and ends player each one. 

  this.setState({ playerOneScore: this.state.playerOneScore + 3 });
  this.setState({ playerTwoScore: this.state.playerTwoScore + 7 });
  this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
  this.setState({ Ends: this.state.Ends + 1 });

  // Now update the arrays - scores so far and end times


  var newArray = this.state.playerOneScoreArray.concat(3);
  var otherNewArray = this.state.playerTwoScoreArray.concat(7); 
  var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
  this.setState({ playerOneScoreArray: newArray })
  this.setState({ playerTwoScoreArray: otherNewArray })
  this.setState({ endTimes: timeArray })

  //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore + 3,
playerTwoScore: this.state.playerTwoScore + 7,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore + 3 >= this.props.gameEndsAtEntry || this.state.playerTwoScore + 7 >= this.props.gameEndsAtEntry) 
  {
    if (this.state.playerOneScore + 3 > this.state.playerTwoScore + 7)
    {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
     // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 3);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 7);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}

         else {
      // Do nothing if there are no stats for this game.
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 3,
      playerTwoScore: this.state.playerTwoScore + 7,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 

  // If player two's score is higher

if (this.state.playerOneScore + 3 < this.state.playerTwoScore + 7)
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
    

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 7);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 3);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// And if the Logged in player is Player Two 

if (this.props.playerSelectEntry === "playerTwo")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    proportionalMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon:playerTwoPointsWonIncrement
    }, {merge:true})
  }

  // and if the logged in player is Player One (and they've lost)

  if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


   else {
// do nothing if this match is excluded from stats. 
   }



   data = {
     winnerName: this.props.playerTwoNameEntry,
     playerOneScore: this.state.playerOneScore  + 3,
 playerTwoScore: this.state.playerTwoScore + 7,
 playerOneEndsWon: this.state.playerOneEndsWon,
 playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
 ends: this.state.Ends + 1,
   };
   this.props.nextStep();
this.props.saveValues(data);

 }

// And if scores are equal

  if (this.state.playerOneScore + 3 === this.state.playerTwoScore + 7)
  {
    console.log('Match Drawn')
    this.setState({ winnerName: "Match Drawn" })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: "Match Drawn",
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 7);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 3);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}

data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 3,
playerTwoScore: this.state.playerTwoScore + 7,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

  }
  }


// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
{

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

  if (this.state.playerOneScore + 3 > this.state.playerTwoScore + 7) {
    this.setState({ winnerName: this.props.playerOneNameEntry });
    console.log('Player One Wins')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

         // Set the increments for overall stats

    const increment = firebase.firestore.FieldValue.increment(1);
    const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
    const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 3);
    const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 7); 
    const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
    const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

    // Now if the logged in Player is Player One, update their overall stats. 

    if (this.props.playerSelectEntry === "playerOne")
      
      {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
        matchesWon: increment,
        proportionalMatchesWon: increment,
        endsPlayed: EndsPlayedIncrement,
        proportionalEndsPlayed: EndsPlayedIncrement,
        endsWon: playerOneEndsWonIncrement,
        proportionalEndsWon: playerOneEndsWonIncrement,
        proportionalPointsAgainst: playerTwoPointsWonIncrement,
        proportionalPointsWon: playerOnePointsWonIncrement
        }, {merge:true})
      }

      // If the logged in player is Player Two, updated their stats. 



      if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  proportionalEndsWon: playerTwoEndsWonIncrement,
  proportionalPointsAgainst: playerOnePointsWonIncrement,
  proportionalPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
}


       else {
    // Do nothing if this match is excluded from stats
       }
      
      
      
       data = {
        winnerName: this.props.playerOneNameEntry,
        playerOneScore: this.state.playerOneScore  + 3,
    playerTwoScore: this.state.playerTwoScore + 7,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }
      

       // Now if Player Two's score his higher


  if (this.state.playerOneScore + 3 < this.state.playerTwoScore + 7) {
    this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: this.props.playerTwoNameEntry,
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

 // Set the increments

 const increment = firebase.firestore.FieldValue.increment(1);
 const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
 const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
 const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 7);
 const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 3);
 const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

 // And if the Logged in player is Player Two 

 if (this.props.playerSelectEntry === "playerTwo")
   
   {
     firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
     matchesWon: increment,
     proportionalMatchesWon: increment,
     endsPlayed: EndsPlayedIncrement,
     proportionalEndsPlayed: EndsPlayedIncrement,
     endsWon: playerTwoEndsWonIncrement,
     proportionalEndsWon: playerTwoEndsWonIncrement,
     proportionalPointsAgainst: playerOnePointsWonIncrement,
     proportionalPointsWon:playerTwoPointsWonIncrement
     }, {merge:true})
   }

   // and if the logged in player is Player One (and they've lost)

   if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


    else {
 // do nothing if this match is excluded from stats. 
    }



    data = {
      winnerName: this.props.playerTwoNameEntry,
      playerOneScore: this.state.playerOneScore  + 3,
  playerTwoScore: this.state.playerTwoScore + 7,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
    };
    this.props.nextStep();
this.props.saveValues(data);

  }

// Finally, if scores are level. 


  if (this.state.playerOneScore + 3 === this.state.playerTwoScore + 7) {
    this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: "Match Drawn",
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 7);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 3);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")

{
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  proportionalEndsWon: playerOneEndsWonIncrement,
  proportionalPointsAgainst: playerTwoPointsWonIncrement,
  proportionalPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
}
// and finally if the logged in player is player two 

if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
matchesDrawn: increment,
proportionalMatchesDrawn: increment,
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
winnerName: "Match Drawn",
playerOneScore: this.state.playerOneScore  + 3,
playerTwoScore: this.state.playerTwoScore + 7,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);
  }
  }
}
}

};

playerOneTwoEight = () => {
  // Set the existing values as current values before we change anything in case we have to reset.

  this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
  this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
  this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

  // Now update the scores, ends and ends player each one. 

  this.setState({ playerOneScore: this.state.playerOneScore + 2 });
  this.setState({ playerTwoScore: this.state.playerTwoScore + 8 });
  this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
  this.setState({ Ends: this.state.Ends + 1 });

  // Now update the arrays - scores so far and end times


  var newArray = this.state.playerOneScoreArray.concat(2);
  var otherNewArray = this.state.playerTwoScoreArray.concat(8); 
  var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
  this.setState({ playerOneScoreArray: newArray })
  this.setState({ playerTwoScoreArray: otherNewArray })
  this.setState({ endTimes: timeArray })

  //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore + 2,
playerTwoScore: this.state.playerTwoScore + 8,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore + 2 >= this.props.gameEndsAtEntry || this.state.playerTwoScore + 8 >= this.props.gameEndsAtEntry) 
  {
    if (this.state.playerOneScore + 2 > this.state.playerTwoScore + 8)
    {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
     // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 2);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 8);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}

         else {
      // Do nothing if there are no stats for this game.
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 2,
      playerTwoScore: this.state.playerTwoScore + 8,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 

  // If player two's score is higher

if (this.state.playerOneScore + 2 < this.state.playerTwoScore + 8)
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
    

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 8);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 2);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// And if the Logged in player is Player Two 

if (this.props.playerSelectEntry === "playerTwo")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    proportionalMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon:playerTwoPointsWonIncrement
    }, {merge:true})
  }

  // and if the logged in player is Player One (and they've lost)

  if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


   else {
// do nothing if this match is excluded from stats. 
   }



   data = {
     winnerName: this.props.playerTwoNameEntry,
     playerOneScore: this.state.playerOneScore  + 2,
 playerTwoScore: this.state.playerTwoScore + 8,
 playerOneEndsWon: this.state.playerOneEndsWon,
 playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
 ends: this.state.Ends + 1,
   };
   this.props.nextStep();
this.props.saveValues(data);

 }

// And if scores are equal

  if (this.state.playerOneScore + 2 === this.state.playerTwoScore + 8)
  {
    console.log('Match Drawn')
    this.setState({ winnerName: "Match Drawn" })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: "Match Drawn",
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 8);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 2);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}

data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 2,
playerTwoScore: this.state.playerTwoScore + 8,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

  }
  }


// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
{

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

  if (this.state.playerOneScore + 2 > this.state.playerTwoScore + 8) {
    this.setState({ winnerName: this.props.playerOneNameEntry });
    console.log('Player One Wins')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

         // Set the increments for overall stats

    const increment = firebase.firestore.FieldValue.increment(1);
    const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
    const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 2);
    const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 8); 
    const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
    const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

    // Now if the logged in Player is Player One, update their overall stats. 

    if (this.props.playerSelectEntry === "playerOne")
      
      {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
        matchesWon: increment,
        proportionalMatchesWon: increment,
        endsPlayed: EndsPlayedIncrement,
        proportionalEndsPlayed: EndsPlayedIncrement,
        endsWon: playerOneEndsWonIncrement,
        proportionalEndsWon: playerOneEndsWonIncrement,
        proportionalPointsAgainst: playerTwoPointsWonIncrement,
        proportionalPointsWon: playerOnePointsWonIncrement
        }, {merge:true})
      }

      // If the logged in player is Player Two, updated their stats. 



      if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  proportionalEndsWon: playerTwoEndsWonIncrement,
  proportionalPointsAgainst: playerOnePointsWonIncrement,
  proportionalPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
}


       else {
    // Do nothing if this match is excluded from stats
       }
      
      
      
       data = {
        winnerName: this.props.playerOneNameEntry,
        playerOneScore: this.state.playerOneScore  + 2,
    playerTwoScore: this.state.playerTwoScore + 8,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }
      

       // Now if Player Two's score his higher


  if (this.state.playerOneScore + 2 < this.state.playerTwoScore + 8) {
    this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: this.props.playerTwoNameEntry,
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

 // Set the increments

 const increment = firebase.firestore.FieldValue.increment(1);
 const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
 const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
 const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 8);
 const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 2);
 const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

 // And if the Logged in player is Player Two 

 if (this.props.playerSelectEntry === "playerTwo")
   
   {
     firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
     matchesWon: increment,
     proportionalMatchesWon: increment,
     endsPlayed: EndsPlayedIncrement,
     proportionalEndsPlayed: EndsPlayedIncrement,
     endsWon: playerTwoEndsWonIncrement,
     proportionalEndsWon: playerTwoEndsWonIncrement,
     proportionalPointsAgainst: playerOnePointsWonIncrement,
     proportionalPointsWon:playerTwoPointsWonIncrement
     }, {merge:true})
   }

   // and if the logged in player is Player One (and they've lost)

   if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


    else {
 // do nothing if this match is excluded from stats. 
    }



    data = {
      winnerName: this.props.playerTwoNameEntry,
      playerOneScore: this.state.playerOneScore  + 2,
  playerTwoScore: this.state.playerTwoScore + 8,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
    };
    this.props.nextStep();
this.props.saveValues(data);

  }

// Finally, if scores are level. 


  if (this.state.playerOneScore + 2 === this.state.playerTwoScore + 8) {
    this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: "Match Drawn",
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 8);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 2);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")

{
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  proportionalEndsWon: playerOneEndsWonIncrement,
  proportionalPointsAgainst: playerTwoPointsWonIncrement,
  proportionalPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
}
// and finally if the logged in player is player two 

if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
matchesDrawn: increment,
proportionalMatchesDrawn: increment,
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
winnerName: "Match Drawn",
playerOneScore: this.state.playerOneScore  + 2,
playerTwoScore: this.state.playerTwoScore + 8,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);
  }
  }
}
}

};

playerOneOneNine = () => {
  // Set the existing values as current values before we change anything in case we have to reset.

  this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
  this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
  this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

  // Now update the scores, ends and ends player each one. 

  this.setState({ playerOneScore: this.state.playerOneScore + 1 });
  this.setState({ playerTwoScore: this.state.playerTwoScore + 9 });
  this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
  this.setState({ Ends: this.state.Ends + 1 });

  // Now update the arrays - scores so far and end times


  var newArray = this.state.playerOneScoreArray.concat(1);
  var otherNewArray = this.state.playerTwoScoreArray.concat(9); 
  var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
  this.setState({ playerOneScoreArray: newArray })
  this.setState({ playerTwoScoreArray: otherNewArray })
  this.setState({ endTimes: timeArray })

  //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore + 1,
playerTwoScore: this.state.playerTwoScore + 9,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore + 1 >= this.props.gameEndsAtEntry || this.state.playerTwoScore + 9 >= this.props.gameEndsAtEntry) 
  {
    if (this.state.playerOneScore + 1 > this.state.playerTwoScore + 9)
    {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
     // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 1);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 9);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}

         else {
      // Do nothing if there are no stats for this game.
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 1,
      playerTwoScore: this.state.playerTwoScore + 9,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 

  // If player two's score is higher

if (this.state.playerOneScore + 1 < this.state.playerTwoScore + 9)
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
    

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 9);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 1);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// And if the Logged in player is Player Two 

if (this.props.playerSelectEntry === "playerTwo")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    proportionalMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon:playerTwoPointsWonIncrement
    }, {merge:true})
  }

  // and if the logged in player is Player One (and they've lost)

  if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


   else {
// do nothing if this match is excluded from stats. 
   }



   data = {
     winnerName: this.props.playerTwoNameEntry,
     playerOneScore: this.state.playerOneScore  + 1,
 playerTwoScore: this.state.playerTwoScore + 9,
 playerOneEndsWon: this.state.playerOneEndsWon,
 playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
 ends: this.state.Ends + 1,
   };
   this.props.nextStep();
this.props.saveValues(data);

 }

// And if scores are equal

  if (this.state.playerOneScore + 1 === this.state.playerTwoScore + 9)
  {
    console.log('Match Drawn')
    this.setState({ winnerName: "Match Drawn" })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: "Match Drawn",
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 9);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 1);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}

data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 1,
playerTwoScore: this.state.playerTwoScore + 9,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

  }
  }


// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
{

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

  if (this.state.playerOneScore + 1 > this.state.playerTwoScore + 9) {
    this.setState({ winnerName: this.props.playerOneNameEntry });
    console.log('Player One Wins')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

         // Set the increments for overall stats

    const increment = firebase.firestore.FieldValue.increment(1);
    const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
    const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 1);
    const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 9); 
    const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
    const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

    // Now if the logged in Player is Player One, update their overall stats. 

    if (this.props.playerSelectEntry === "playerOne")
      
      {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
        matchesWon: increment,
        proportionalMatchesWon: increment,
        endsPlayed: EndsPlayedIncrement,
        proportionalEndsPlayed: EndsPlayedIncrement,
        endsWon: playerOneEndsWonIncrement,
        proportionalEndsWon: playerOneEndsWonIncrement,
        proportionalPointsAgainst: playerTwoPointsWonIncrement,
        proportionalPointsWon: playerOnePointsWonIncrement
        }, {merge:true})
      }

      // If the logged in player is Player Two, updated their stats. 



      if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  proportionalEndsWon: playerTwoEndsWonIncrement,
  proportionalPointsAgainst: playerOnePointsWonIncrement,
  proportionalPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
}


       else {
    // Do nothing if this match is excluded from stats
       }
      
      
      
       data = {
        winnerName: this.props.playerOneNameEntry,
        playerOneScore: this.state.playerOneScore  + 1,
    playerTwoScore: this.state.playerTwoScore + 9,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }
      

       // Now if Player Two's score his higher


  if (this.state.playerOneScore + 1 < this.state.playerTwoScore + 9) {
    this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: this.props.playerTwoNameEntry,
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

 // Set the increments

 const increment = firebase.firestore.FieldValue.increment(1);
 const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
 const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
 const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 9);
 const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 1);
 const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

 // And if the Logged in player is Player Two 

 if (this.props.playerSelectEntry === "playerTwo")
   
   {
     firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
     matchesWon: increment,
     proportionalMatchesWon: increment,
     endsPlayed: EndsPlayedIncrement,
     proportionalEndsPlayed: EndsPlayedIncrement,
     endsWon: playerTwoEndsWonIncrement,
     proportionalEndsWon: playerTwoEndsWonIncrement,
     proportionalPointsAgainst: playerOnePointsWonIncrement,
     proportionalPointsWon:playerTwoPointsWonIncrement
     }, {merge:true})
   }

   // and if the logged in player is Player One (and they've lost)

   if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


    else {
 // do nothing if this match is excluded from stats. 
    }



    data = {
      winnerName: this.props.playerTwoNameEntry,
      playerOneScore: this.state.playerOneScore  + 1,
  playerTwoScore: this.state.playerTwoScore + 9,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
    };
    this.props.nextStep();
this.props.saveValues(data);

  }

// Finally, if scores are level. 


  if (this.state.playerOneScore + 1 === this.state.playerTwoScore + 9) {
    this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: "Match Drawn",
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 9);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 1);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")

{
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  proportionalEndsWon: playerOneEndsWonIncrement,
  proportionalPointsAgainst: playerTwoPointsWonIncrement,
  proportionalPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
}
// and finally if the logged in player is player two 

if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
matchesDrawn: increment,
proportionalMatchesDrawn: increment,
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
winnerName: "Match Drawn",
playerOneScore: this.state.playerOneScore  + 1,
playerTwoScore: this.state.playerTwoScore + 9,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);
  }
  }
}
}

};


playerOneZeroTen = () => {
  // Set the existing values as current values before we change anything in case we have to reset.

  this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
  this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
  this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })

  // Now update the scores, ends and ends player each one. 

  this.setState({ playerOneScore: this.state.playerOneScore});
  this.setState({ playerTwoScore: this.state.playerTwoScore + 10 });
  this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
  this.setState({ Ends: this.state.Ends + 1 });

  // Now update the arrays - scores so far and end times


  var newArray = this.state.playerOneScoreArray.concat(0);
  var otherNewArray = this.state.playerTwoScoreArray.concat(10); 
  var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
  this.setState({ playerOneScoreArray: newArray })
  this.setState({ playerTwoScoreArray: otherNewArray })
  this.setState({ endTimes: timeArray })

  //update the live scores in Firestore

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
playerOneScore: this.state.playerOneScore,
playerTwoScore: this.state.playerTwoScore + 10,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
endNumber: this.state.Ends + 1,
playerOneEndScores: newArray,
playerTwoEndScores: otherNewArray,
endTimes: timeArray,
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Check if either player has passed the winning score and finish the match if they have. 

if (this.props.gameTypeEntry === 'Points')
{
  if (this.state.playerOneScore >= this.props.gameEndsAtEntry || this.state.playerTwoScore + 10 >= this.props.gameEndsAtEntry) 
  {
    if (this.state.playerOneScore > this.state.playerTwoScore + 10)
    {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
     // Update Overall Win Stats

      // first set all of the incremental values

      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 10);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

      // now if the logged in player is Player One, update their Firestore stats. 

      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          proportionalMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          proportionalEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          proportionalEndsWon: playerOneEndsWonIncrement,
          proportionalPointsWon:playerOnePointsWonIncrement,
          proportionalPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }

        // Do the same if they are Player Two 

        if (this.props.playerSelectEntry === "playerTwo")
{

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsWon: playerTwoPointsWonIncrement,
    proportionalPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
}

         else {
      // Do nothing if there are no stats for this game.
         }

         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 10,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };

this.props.nextStep();
this.props.saveValues(data);
  } 

  // If player two's score is higher

if (this.state.playerOneScore < this.state.playerTwoScore + 10)
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
    

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 10);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// And if the Logged in player is Player Two 

if (this.props.playerSelectEntry === "playerTwo")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    proportionalMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    proportionalEndsWon: playerTwoEndsWonIncrement,
    proportionalPointsAgainst: playerOnePointsWonIncrement,
    proportionalPointsWon:playerTwoPointsWonIncrement
    }, {merge:true})
  }

  // and if the logged in player is Player One (and they've lost)

  if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


   else {
// do nothing if this match is excluded from stats. 
   }



   data = {
     winnerName: this.props.playerTwoNameEntry,
     playerOneScore: this.state.playerOneScore,
 playerTwoScore: this.state.playerTwoScore + 10,
 playerOneEndsWon: this.state.playerOneEndsWon,
 playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
 ends: this.state.Ends + 1,
   };
   this.props.nextStep();
this.props.saveValues(data);

 }

// And if scores are equal

  if (this.state.playerOneScore === this.state.playerTwoScore + 10)
  {
    console.log('Match Drawn')
    this.setState({ winnerName: "Match Drawn" })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: "Match Drawn",
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 10);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    proportionalMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    proportionalEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    proportionalEndsWon: playerOneEndsWonIncrement,
    proportionalPointsAgainst: playerTwoPointsWonIncrement,
    proportionalPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
// and finally if the logged in player is player two 

  if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}

data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore,
playerTwoScore: this.state.playerTwoScore + 10,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);

  }
  }


// Now check if the game has reached the final number of ends

if (this.props.gameTypeEntry === 'Ends') {
if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
{

// if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 

  if (this.state.playerOneScore > this.state.playerTwoScore + 10) {
    this.setState({ winnerName: this.props.playerOneNameEntry });
    console.log('Player One Wins')
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });

         // Set the increments for overall stats

    const increment = firebase.firestore.FieldValue.increment(1);
    const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
    const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
    const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 10); 
    const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
    const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

    // Now if the logged in Player is Player One, update their overall stats. 

    if (this.props.playerSelectEntry === "playerOne")
      
      {
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
        matchesWon: increment,
        proportionalMatchesWon: increment,
        endsPlayed: EndsPlayedIncrement,
        proportionalEndsPlayed: EndsPlayedIncrement,
        endsWon: playerOneEndsWonIncrement,
        proportionalEndsWon: playerOneEndsWonIncrement,
        proportionalPointsAgainst: playerTwoPointsWonIncrement,
        proportionalPointsWon: playerOnePointsWonIncrement
        }, {merge:true})
      }

      // If the logged in player is Player Two, updated their stats. 



      if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  proportionalEndsWon: playerTwoEndsWonIncrement,
  proportionalPointsAgainst: playerOnePointsWonIncrement,
  proportionalPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
}


       else {
    // Do nothing if this match is excluded from stats
       }
      
      
      
       data = {
        winnerName: this.props.playerOneNameEntry,
        playerOneScore: this.state.playerOneScore,
    playerTwoScore: this.state.playerTwoScore + 10,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
this.props.saveValues(data);

    }
      

       // Now if Player Two's score his higher


  if (this.state.playerOneScore < this.state.playerTwoScore + 10) {
    this.setState({ winnerName: this.props.playerTwoNameEntry })
console.log('Player Two Wins')
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: this.props.playerTwoNameEntry,
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

 // Set the increments

 const increment = firebase.firestore.FieldValue.increment(1);
 const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
 const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
 const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 10);
 const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
 const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

 // And if the Logged in player is Player Two 

 if (this.props.playerSelectEntry === "playerTwo")
   
   {
     firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
     matchesWon: increment,
     proportionalMatchesWon: increment,
     endsPlayed: EndsPlayedIncrement,
     proportionalEndsPlayed: EndsPlayedIncrement,
     endsWon: playerTwoEndsWonIncrement,
     proportionalEndsWon: playerTwoEndsWonIncrement,
     proportionalPointsAgainst: playerOnePointsWonIncrement,
     proportionalPointsWon:playerTwoPointsWonIncrement
     }, {merge:true})
   }

   // and if the logged in player is Player One (and they've lost)

   if (this.props.playerSelectEntry === "playerOne")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerOneEndsWonIncrement,
proportionalEndsWon: playerOneEndsWonIncrement,
proportionalPointsAgainst: playerTwoPointsWonIncrement,
proportionalPointsWon: playerOnePointsWonIncrement
}, {merge:true})
}


    else {
 // do nothing if this match is excluded from stats. 
    }



    data = {
      winnerName: this.props.playerTwoNameEntry,
      playerOneScore: this.state.playerOneScore,
  playerTwoScore: this.state.playerTwoScore + 10,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
    };
    this.props.nextStep();
this.props.saveValues(data);

  }

// Finally, if scores are level. 


  if (this.state.playerOneScore === this.state.playerTwoScore + 10) {
    this.setState({ winnerName: "Match Drawn" })
firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Proportional").doc(this.state.matchID).set({
winnerName: "Match Drawn",
matchEnd: new Date().toLocaleTimeString(),
matchComplete: true, 
}, {merge: true})
.catch((error) => {
console.log(error);
});

// Set the increments

const increment = firebase.firestore.FieldValue.increment(1);
const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 10);
const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)

// If the logged in player is PlayerTwo

if (this.props.playerSelectEntry === "playerOne")

{
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  proportionalMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  proportionalEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  proportionalEndsWon: playerOneEndsWonIncrement,
  proportionalPointsAgainst: playerTwoPointsWonIncrement,
  proportionalPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
}
// and finally if the logged in player is player two 

if (this.props.playerSelectEntry === "playerTwo")
{

firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
matchesDrawn: increment,
proportionalMatchesDrawn: increment,
endsPlayed: EndsPlayedIncrement,
proportionalEndsPlayed: EndsPlayedIncrement,
endsWon: playerTwoEndsWonIncrement,
proportionalEndsWon: playerTwoEndsWonIncrement,
proportionalPointsAgainst: playerOnePointsWonIncrement,
proportionalPointsWon: playerTwoPointsWonIncrement
}, {merge:true})
}



data = {
winnerName: "Match Drawn",
playerOneScore: this.state.playerOneScore,
playerTwoScore: this.state.playerTwoScore + 10,
playerOneEndsWon: this.state.playerOneEndsWon,
playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
ends: this.state.Ends + 1,
};
this.props.nextStep();
this.props.saveValues(data);
  }
  }
}
}

};

componentDidMount() {
  this.createMatchRecord()
}

  render() {
    return (
      <div>
        <table class="table table-sm table-borderless" style={{textAlign:"center"}}>
          <tr  style={{cellPadding: "5px"}}>
            <td width="50%">
        
          <h3>{ this.props.playerOneNameEntry }</h3>
          <h2>
          {this.state.playerOneScore}</h2>
          </td>
          <td width="50%">
          
        <h3>{ this.props.playerTwoNameEntry }</h3>
        <h2>
          {this.state.playerTwoScore}
        </h2>

        </td>
        </tr>
        <tr>
          <td colspan="2">
          
         <p>Ends: {this.state.Ends}</p>
       

          </td>
        </tr>
        <tr>

<td colspan="2"> <p>Match Venue:  { this.props.matchVenueEntry }</p>
        <p>Game Ends After { this.props.gameEndsAtEntry } { this.props.gameTypeEntry }</p></td>

     </tr>
        <tr>
          <td>
          { this.props.playerOneNameEntry }
          </td>
          <td>
          { this.props.playerTwoNameEntry }
          </td>
        </tr>


<tr>

  <td>
  <button
          onClick={this.playerOneTenZero}
          className="btn btn-secondary btn-sm"
        >
          10 - 0
        </button>
        </td>
        <td>
        <button
          onClick={this.playerOneZeroTen}
          className="btn btn-secondary btn-sm"
        >
          10 - 0
        </button>
          </td>
</tr>
<tr>
<td>
<button
          onClick={this.playerOneNineOne}
          className="btn btn-secondary btn-sm"
        >
          9 - 1 
        </button>
</td>
<td>
<button
          onClick={this.playerOneOneNine}
          className="btn btn-secondary btn-sm"
        >
          9 - 1
        </button>

</td>

</tr>

<tr>
<td>

<button
          onClick={this.playerOneEightTwo}
          className="btn btn-secondary btn-sm"
        >
          8 - 2
        </button>
</td>
<td>

<button
          onClick={this.playerOneTwoEight}
          className="btn btn-secondary btn-sm"
        >
         8 - 2
        </button>

</td>

</tr>
<tr>
<td>
       
       
        <button
          onClick={this.playerOneSevenThree}
          className="btn btn-secondary btn-sm"
        >
          7 - 3
        </button>
  </td>

  <td>
       
  
        <button
          onClick={this.playerOneThreeSeven}
          className="btn btn-secondary btn-sm"
        >
          7 - 3
        </button>

  </td>
</tr>

<tr>
<td>
       
       
        <button
          onClick={this.playerOneSixFour}
          className="btn btn-secondary btn-sm"
        >
          6 - 4
        </button>
  </td>

  <td>
       
  
        <button
          onClick={this.playerOneFourSix}
          className="btn btn-secondary btn-sm"
        >
          6 - 4
        </button>

  </td>
</tr>

<tr>
<td colspan="2">
<button className="btn btn-primary btn-sm" onClick={this.fiveFive}>5-5</button>
  </td>
  </tr>
  <tr>
  <td colspan="2">
<button className="btn btn-primary btn-sm" onClick={this.undoButton}>Undo</button>
  </td>
</tr>

</table>

      </div>
    );
  }

  getBadgeClasses() {
    let classes = "badge m-2 badge-";
    classes += this.state.playerOneScore === 0 ? "warning" : "primary";
    return classes;
  }
  formatCount() {
    const { playerOneScore } = this.state;
    return playerOneScore;
  }
}

export default Proportionalmatch;
