import React, { Component } from "react";
import firebase from "firebase";
import "firebase/firestore";
import ReactDOM from "react-dom";


class About extends Component {
  constructor(props) {
    super(props);
   this.getData = this.getData.bind(this);
   this.handleChange = this.handleChange.bind(this);
   this.updateData = this.updateData.bind(this);
    this.state ={
      value: '',
firstname: "",
lastname: "",
bowlsClub: "",
postcode: "",
mobile: "",
response: "",
    }



    
  }

  getData() {

    var that = this
    
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
    
   var docRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);

   docRef.get().then(function(doc) {

        console.log("Document data:", doc.data().firstname);
        that.setState({firstname: doc.data().firstname, lastname: doc.data().lastname, 
          bowlsClub: doc.data().bowlsClub, postcode: doc.data().postcode,
        mobile: doc.data().mobile, email: doc.data().email });
    
}).catch(function(error) {
    console.log("Error getting document:", error);
}).catch(function(error) {
  console.log("Got an error: ",error);
  });
} else {
  // No user is signed in.
}
});
} 

updateData() {

  var that = this
  
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
  
 var docRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);

 docRef.set({
  firstname: ReactDOM.findDOMNode(that.refs.firstname).value,
  lastname: ReactDOM.findDOMNode(that.refs.lastname).value,
  mobile: ReactDOM.findDOMNode(that.refs.mobile).value,
  postcode: ReactDOM.findDOMNode(that.refs.postcode).value,
  bowlsClub: ReactDOM.findDOMNode(that.refs.bowlsClub).value
}, {merge: true}
).then(function(doc) {
  that.setState({response: "Details Updated Successfully"})
}).catch(function(error) {
  console.log("Error updating records:", error);
  that.setState({response:"Error updating records:" + error})
}).catch(function(error) {
console.log("Got an error: ",error);
that.setState({response:"Error updating records:" + error})
});
} else {
// No user is signed in.
}
});
} 

componentDidMount() {
  {this.getData()} 

}

handleChange(event) {
this.setState({ value: event.target.value });
}

  render() { 
    return (
      <div>



<h1>Your Account</h1>

<p><b>Change your details below</b></p>

<p>Your email address: {this.state.email} </p>
      
<input
        class="form-control"
          type="text"
          ref="firstname"
          placeholder="First Name"
          defaultValue={this.state.firstname}
          onChange={this.handleChange}
            style={{ marginBottom: "0.8rem" }}
         
        />

<input
        class="form-control"
          type="text"
          ref="lastname"
          placeholder="Last Name"
          defaultValue={this.state.lastname}
          onChange={this.handleChange}
            style={{ marginBottom: "0.8rem" }}
         
        />

<input
        class="form-control"
          type="text"
          ref="mobile"
          placeholder="Mobile"
          defaultValue={this.state.mobile}
          onChange={this.handleChange}
            style={{ marginBottom: "0.8rem" }}
         
        />

<input
        class="form-control"
          type="text"
          ref="bowlsClub"
          placeholder="Bowls Club"
          defaultValue={this.state.bowlsClub}
          onChange={this.handleChange}
            style={{ marginBottom: "0.8rem" }}
         
        />

<input
        class="form-control"
          type="text"
          ref="postcode"
          placeholder="Post Code"
          defaultValue={this.state.postcode}
          onChange={this.handleChange}
            style={{ marginBottom: "0.8rem" }}
         
        />
    
        <button onClick={this.updateData} className="btn btn-primary">Update</button>
<p><a href="/forgotpassword">Change password?</a></p>
<p class="errorMessage">{this.state.response}</p>
      

  
  
      
      </div>
    );
  }
}



export default About;
