import React, { Component } from "react";
import fire from "./config/fire";




class LoginScreen extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.state = {
      email: "",
      password: "",
    }
  }

  logOut() {
    fire.auth().signOut();
  }

  login(e) {
    e.preventDefault();
    fire
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((u) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <div style={{
       justifyContent: "center",
      }}>

 <h1 style={{  marginTop: "30px", marginBottom: "20px" }}>Welcome back</h1>

 <form>
   <div className="form-row">
     
       <input
         className="form-control"
         type="text"
         name="email"
         placeholder="Email address"
         style={{ marginBottom: "0.8rem" }}
         onChange={this.handleChange}
         value={this.state.email}
       />
   
       <input
         className="form-control"
         type="password"
         name="password"
         placeholder="Password"
         style={{ marginBottom: "0.8rem" }}
         onChange={this.handleChange}
         value={this.state.password}
       />
     
     <button className="btn btn-primary" style={{
       justifyContent: "center",
      }} onClick={this.login}>Login</button>
   </div>
 </form>
 <p>New user? Click here to <a href="/">create an account</a></p>
 <p><a href="/forgotpassword">Forgot Password?</a></p>
 <p><sup>We use cookies to help make rollUp better. By visiting this site, you agree to our cookie policy.</sup></p>
 </div>

);
}
}
export default LoginScreen;