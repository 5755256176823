import "firebase/firestore";
import Resumeproportionalmatch from "./ResumeProportionalMatch"
import SuccessProportional from "./SuccessProportional"


var React = require("react");

var assign = require("object-assign");




var matchValues = {
  playerOneName: null,
  playerTwoName: null,
  matchVenue: null,
  gameEndsAt: 21,
  gameType: null,
  winnerName: null,
  matchID: null,
};


 class ResumeProportional extends React.Component {
  constructor(props) {
    super(props);
    this.nextStep = this.nextStep.bind(this)
    this.state = {
      step: 1
    };
  }

  nextStep () {
    this.setState({
      step: this.state.step + 1,
    });
  }

  previousStep () {
    this.setState({
      step: this.state.step - 1,
    });
  }

 saveValues (field_value) {
    return function () {
      matchValues = assign({}, matchValues, field_value);
    }();
  }
  
  
    ShowStep () {
      switch (this.state.step) {
        case 1:
          return (
            <Resumeproportionalmatch playerOneNameEntry={ this.props.location.playerOneName }
            playerTwoNameEntry={ this.props.location.playerTwoName } 
            playerOneScoreEntry={ this.props.location.playerOneScore } 
            playerTwoScoreEntry={this.props.location.playerTwoScore}
            playerOneEndScoresEntry = {this.props.location.playerOneEndScores}
            playerTwoEndScoresEntry = {this.props.location.playerTwoEndScores}
            endNumberEntry = {this.props.location.endNumber }
            endTimesEntry = {this.props.location.endTimes }
            matchVenueEntry = {this.props.location.matchVenue }
            gameEndsAtEntry = {this.props.location.gameEndsAt }
            gameTypeEntry = { this.props.location.gameType }
            playerOneEndsWonEntry = { this.props.location.playerOneEndsWon }
            playerTwoEndsWonEntry = { this.props.location.playerTwoEndsWon }
      
            matchID= {this.props.location.matchID}
            nextStep={this.nextStep}
            saveValues={this.saveValues}
      
         
         />
          );

        case 2:
          return <SuccessProportional matchValues={matchValues} />;
      }
    }
  

    
    

  
    

  render() {    
   // const matchID = this.props.matchID;
    console.log(this.props.location.matchID)

    console.log(this.props.location.playerOneScore )

        
    return (
        
        
        <div>
      
  {this.ShowStep() }

 


      </div>
  )
    }
}


export default ResumeProportional