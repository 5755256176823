import Pairsmatch from './pairsmatch'
var React = require("react");


 class PairsMatchScreen extends React.Component {

    
  render() {    


        
    return (
        
        
        <div>
      <Pairsmatch playerOneNameEntry={ this.props.matchValues.playerOneName }
      teamOneSkipEntry={ this.props.matchValues.teamOneSkip }
      teamOneLeadEntry={ this.props.matchValues.teamOneLead }
      teamTwoSkipEntry={ this.props.matchValues.teamTwoSkip }
      teamTwoLeadEntry={ this.props.matchValues.teamTwoLead } 
      playerSelectEntry={ this.props.matchValues.playerSelect }
                    playerTwoNameEntry={ this.props.matchValues.playerTwoName } 
                    matchVenueEntry= { this.props.matchValues.matchVenue }
                    gameEndsAtEntry= { this.props.matchValues.gameEndsAt }
                    gameTypeEntry= { this.props.matchValues.gameType }
                    matchIDEntry = {  this.props.matchValues.matchID }
                    nextStep = { this.props.nextStep }
                    saveValues = { this.props.saveValues }
      />
      </div>
  )
    }
}


export default PairsMatchScreen