import React from "react";
var createReactClass = require("create-react-class");
var NameFields = require("./NameFields").default;
var ClubFields = require("./ClubFields").default;
var LoginFields = require("./LoginFields").default;
var Success = require("./MatchComponents/Success").default;
var assign = require("object-assign");

var fieldValues = {
  firstname: null,
  lastname: null,
  mobile: null,
  postcode: null,
  bowlsClub: null,
  password: null,
  email: null,
};

var Signup = createReactClass({
  getInitialState: function () {
    return {
      step: 1,
    };
  },

  saveValues: function (field_value) {
    return function () {
      fieldValues = assign({}, fieldValues, field_value);
    }();
  },

  nextStep: function () {
    this.setState({
      step: this.state.step + 1,
    });
  },

  // Same as nextStep, but decrementing
  previousStep: function () {
    this.setState({
      step: this.state.step - 1,
    });
  },

  ShowStep: function () {
    switch (this.state.step) {
      case 1:
        return (
          <NameFields
            fieldValues={fieldValues}
            nextStep={this.nextStep}
            saveValues={this.saveValues}
          />
        );
      case 2:
        return (
          <ClubFields
            fieldValues={fieldValues}
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            saveValues={this.saveValues}
          />
        );
      case 3:
        return (
          <LoginFields
            fieldValues={fieldValues}
            previousStep={this.previousStep}
            submitRegistration={this.submitRegistration}
          />
        );
      case 4:
        return <Success fieldValues={fieldValues} />;
    }
  },

  render: function () {

    return <main>
      <div style={{marginBottom:"10px"}}>{this.ShowStep()}</div>
    <p>Already a Member? <a href="/login">Log In</a></p>
    <p>Click here to read our <a href="http://rollupbowls.com.au/privacy-policy/">Privacy Policy</a></p>
    <p><sup>We use cookies to help make rollUp better. By visiting this site, you agree to our cookie policy.</sup></p>
    </main>;
  },
});

export default Signup;
