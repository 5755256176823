import React from "react";
import firebase from "firebase";
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";





class SavedMatchDetails extends React.Component {
  
  constructor(props) {
    super(props);
    this.getMatchList = this.getMatchList.bind(this);
    this.deleteMatch = this.deleteMatch.bind(this);
    this.mailGun = this.mailGun.bind(this);
    this.state = {
      data: "",
        }
  }

  mailGun(e) {
    e.preventDefault();
    const mailgun = require("mailgun-js");
    const DOMAIN = 'mg.rollupbowls.com.au';
    const RECIPIENT = ReactDOM.findDOMNode(this.refs.emailSend).value
    console.log(RECIPIENT)
    const mg = mailgun({apiKey: 'aa17a0e1fe121808a4e0bbcc99b04b3d-cb3791c4-c9226b97', domain: DOMAIN});
    const data = {
      from: 'rollUp Scorecard <donotreply@rollupbowls.com.au>',
      to: RECIPIENT,
      subject: 'rollUp Scorecard - Match Result',
      text: 'Hi, we have been asked to send you a match score from the rollUp Scorecard App. In the game between ' +
      this.state.data.playerOneName +  ' and ' + this.state.data.playerTwoName + ' at ' + this.state.data.matchVenue + ', the score was ' +
      this.state.data.playerOneName + ' (' + this.state.data.playerOneScore + ') to ' + this.state.data.playerTwoName + ' (' + this.state.data.playerTwoScore + ') after ' + this.state.data.endNumber + ' ends. The match started at ' +
      this.state.data.matchDate + ' and finished at ' +  this.state.data.matchEnd + '. Find out more and get your free account at rollupbowls.com.au'
    };
    
    mg.messages().send(data, function (error, body) {
      console.log(body);
      console.log('message sent to' + RECIPIENT)
    });
  
  } 
  getMatchList() {

    var that = this
    
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        console.log(that.props.matchValues.matchType, that.props.matchValues.matchID)
   var docRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection(that.props.matchValues.matchType).doc(that.props.matchValues.matchID);
console.log(that.props.matchValues.matchType)
   docRef.get().then(function(doc) {
    if (doc.exists) {
        console.log("Document data:", doc.data());
     that.setState({ data:doc.data() })
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
}).catch(function(error) {
    console.log("Error getting document:", error);
});
} else {
  // No user is signed in.
}
});
} 

deleteMatch () {

  var that = this
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
 var docRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection(that.props.matchValues.matchType).doc(that.props.matchValues.matchID);

 docRef.delete().then(function(doc) {
  if (doc.exists) {
      console.log("Document Deleted");
      that.props.previousStep()
  } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
  }
}).catch(function(error) {
  that.props.previousStep();
});
} else {
// No user is signed in.
}
});
} 

resumeButton() {
  var that = this
  console.log (that.props.matchValues.matchType)
  console.log (that.state.data.matchComplete)
if (that.state.data.matchComplete === false) {
if (that.props.matchValues.matchType === "Singles") {

 return( <p><Link
  to={{
    pathname: `/resumeSingles/`, 
    matchID: this.state.data.matchID,
    playerOneName: this.state.data.playerOneName,
    playerTwoName: this.state.data.playerTwoName,
    playerOneScore: this.state.data.playerOneScore,
    playerTwoScore: this.state.data.playerTwoScore,
    playerOneEndScores: this.state.data.playerOneEndScores,
    playerTwoEndScores: this.state.data.playerTwoEndScores,
  endTimes: this.state.data.endTimes,
  endNumber: this.state.data.endNumber,
  matchVenue: this.state.data.matchVenue,
  gameEndsAt: this.state.data.gameEndsAt,
  gameType: this.state.data.gameType
  

 }
  }>
  Resume
</Link></p> )
}
if (that.props.matchValues.matchType === "Pairs") {
  return (
  <p><Link
  to={{
    pathname: `/resumePairs/`, 
    matchID: this.state.data.matchID,
    playerOneName: this.state.data.playerOneName,
    playerTwoName: this.state.data.playerTwoName,
    playerOneScore: this.state.data.playerOneScore,
    playerTwoScore: this.state.data.playerTwoScore,
    playerOneEndScores: this.state.data.playerOneEndScores,
    playerTwoEndScores: this.state.data.playerTwoEndScores,
  endTimes: this.state.data.endTimes,
  endNumber: this.state.data.endNumber,
  matchVenue: this.state.data.matchVenue,
  gameEndsAt: this.state.data.gameEndsAt,
  gameType: this.state.data.gameType
  

 }
  }>
  Resume
</Link></p>
  )}
}
if (that.props.matchValues.matchType === "Triples") {

  return( <p><Link
   to={{
     pathname: `/resumeTriples/`, 
     matchID: this.state.data.matchID,
     playerOneName: this.state.data.playerOneName,
     playerTwoName: this.state.data.playerTwoName,
     playerOneScore: this.state.data.playerOneScore,
     playerTwoScore: this.state.data.playerTwoScore,
     playerOneEndScores: this.state.data.playerOneEndScores,
     playerTwoEndScores: this.state.data.playerTwoEndScores,
   endTimes: this.state.data.endTimes,
   endNumber: this.state.data.endNumber,
   matchVenue: this.state.data.matchVenue,
   gameEndsAt: this.state.data.gameEndsAt,
   gameType: this.state.data.gameType
   
 
  }
   }>
   Resume
 </Link></p> )
 }
 if (that.props.matchValues.matchType === "Fours") {

  return( <p><Link
   to={{
     pathname: `/resumeFours/`, 
     matchID: this.state.data.matchID,
     playerOneName: this.state.data.playerOneName,
     playerTwoName: this.state.data.playerTwoName,
     playerOneScore: this.state.data.playerOneScore,
     playerTwoScore: this.state.data.playerTwoScore,
     playerOneEndScores: this.state.data.playerOneEndScores,
     playerTwoEndScores: this.state.data.playerTwoEndScores,
   endTimes: this.state.data.endTimes,
   endNumber: this.state.data.endNumber,
   matchVenue: this.state.data.matchVenue,
   gameEndsAt: this.state.data.gameEndsAt,
   gameType: this.state.data.gameType
   
 
  }
   }>
   Resume
 </Link></p> )
 }
else 
{
 return ( "Match Complete" )
}

}


componentDidMount() {
  this.getMatchList() 
}

  render() {
    
    return (
      <div>
       <h1>Match Details</h1>
       <p>
{this.state.data.playerOneName } v { this.state.data.playerTwoName }
       </p>
       <p>Match played at { this.state.data.matchVenue } </p>
       <p>{ this.state.data.playerOneScore} - { this.state.data.playerTwoScore } after { this.state.data.endNumber } ends.</p>
       <p>Match started: {this.state.data.matchDate}</p>
       <p>Match concluded: {this.state.data.matchEnd}</p>
       <center>
       <form>
       <div className="form-row">
       <div class="col-sm-3">
         <p>Email results:</p>
         </div>
         <div class="col-sm-7">
         <input
        class="form-control"
          type="text"
          ref="emailSend"
          placeholder="Enter email address"
            style={{ marginBottom: "0.8rem" }}
        />
        </div>
        <p>
       <button onClick={this.mailGun} return false className="btn btn-primary">Send</button>
       </p>
      </div>
       </form>
       </center>
       <p><button onClick={this.props.nextStep} className="btn btn-primary">View Scorecard</button></p>
       <p><button onClick={this.deleteMatch}  className="btn btn-danger">Delete Match</button></p>
       <p><button onClick={this.props.previousStep} className="btn btn-primary">Back</button></p>
       { this.resumeButton() }

       
      </div>
    );
  }
}
export default SavedMatchDetails;
