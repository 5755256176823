import React from "react";
import "firebase/firestore";

var createReactClass = require("create-react-class");

var PairsPlayerNameFields = require("./pairsPlayerNameFields").default;
var PairsMatchDetails = require("./PairsMatchDetails2").default;
var PairsMatchScreen = require("./PairsMatchScreen").default;
var SuccessPairs = require("./SuccessPairs").default;
var assign = require("object-assign");


var matchValues = {
  playerOneName: null,
  playerTwoName: null,
  matchVenue: null,
  gameEndsAt: 21,
  gameType: null,
  winnerName: null,
  matchID: null,
};

var Startpairsmatch = createReactClass({
  getInitialState: function () {
    return {
      step: 1,
    };
  },

  saveValues: function (field_value) {
    return function () {
      matchValues = assign({}, matchValues, field_value);
    }();
  },

  nextStep: function () {
    this.setState({
      step: this.state.step + 1,
    });
  },

  // Same as nextStep, but decrementing
  previousStep: function () {
    this.setState({
      step: this.state.step - 1,
    });
  },




  ShowStep: function () {
    switch (this.state.step) {
      case 1:
        return (
          <PairsPlayerNameFields
            matchValues={matchValues}
            nextStep={this.nextStep}
            saveValues={this.saveValues}
          />
        );
      case 2:
        return (
          <PairsMatchDetails
            matchValues={matchValues}
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            saveValues={this.saveValues}
            //commenceMatch={this.commenceMatch}
          />
        );
      case 3:
        return (
          <PairsMatchScreen
            matchValues={matchValues}
            previousStep={this.previousStep}
            nextStep={this.nextStep}
            saveValues={this.saveValues}

          />
        );
      case 4:
        return <SuccessPairs matchValues={matchValues} />;
    }
  },


    render: function () {
      
  
      return <main>
        
        {this.ShowStep()}
        
        </main>;
    },
  });

export default Startpairsmatch;


