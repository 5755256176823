import React from "react";
import "firebase/firestore";


var createReactClass = require("create-react-class");

var react = require("react");
var FoursPlayerNameFields = require("./foursPlayerNameFields").default;
var FoursMatchDetails = require("./FoursMatchDetails2").default;
var FoursMatchScreen = require("./FoursMatchScreen").default;
var SuccessFours = require("./SuccessFours").default;
var assign = require("object-assign");


var matchValues = {
  playerOneName: null,
  playerTwoName: null,
  matchVenue: null,
  gameEndsAt: 21,
  gameType: null,
  winnerName: null,
  matchID: null,
};

var Startfoursmatch = createReactClass({
  getInitialState: function () {
    return {
      step: 1,
    };
  },

  saveValues: function (field_value) {
    return function () {
      matchValues = assign({}, matchValues, field_value);
    }.bind(this)();
  },

  nextStep: function () {
    this.setState({
      step: this.state.step + 1,
    });
  },

  // Same as nextStep, but decrementing
  previousStep: function () {
    this.setState({
      step: this.state.step - 1,
    });
  },




  ShowStep: function () {
    switch (this.state.step) {
      case 1:
        return (
          <FoursPlayerNameFields
            matchValues={matchValues}
            nextStep={this.nextStep}
            saveValues={this.saveValues}
          />
        );
      case 2:
        return (
          <FoursMatchDetails
            matchValues={matchValues}
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            saveValues={this.saveValues}
            //commenceMatch={this.commenceMatch}
          />
        );
      case 3:
        return (
          <FoursMatchScreen
            matchValues={matchValues}
            previousStep={this.previousStep}
            nextStep={this.nextStep}
            saveValues={this.saveValues}

          />
        );
      case 4:
        return <SuccessFours matchValues={matchValues} />;
    }
  },


    render: function () {
      var style = {
        width: (this.state.step / 4) * 100 + "%",
      };
  
      return <main>
        
        {this.ShowStep()}
        
        </main>;
    },
  });

export default Startfoursmatch;


