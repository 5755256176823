import ReactDOM from "react-dom";
import fire from "./config/fire";
import firebase from "firebase";
import "firebase/firestore";

var React = require("react");
var createReactClass = require("create-react-class");

var LoginFields = createReactClass({
  render: function () {
    return (
      <div>
        <h1 style={{  marginTop: "30px", marginBottom: "20px" }}> Create your login details... </h1>
        <input
        className="form-control"
          type="text"
          ref="email"
          placeholder="Email"
            style={{ marginBottom: "0.8rem" }}
          defaultValue={this.props.fieldValues.email}
        />
        
        <input
        className="form-control"
          type="password"
          ref="password"
          placeholder="Passsword"
            style={{ marginBottom: "0.8rem" }}
          defaultValue={this.props.fieldValues.password}
        />
        <button onClick={this.submitRegistration} className="btn btn-primary">Create Account</button>
      </div>
    );
  },

  submitRegistration: function (e) {
    var that = this;
    e.preventDefault();

    // Get values via this.refs
    var data2 = {
      email: ReactDOM.findDOMNode(this.refs.email).value,
      password: ReactDOM.findDOMNode(this.refs.password).value,
      firstname: this.props.fieldValues.firsttname,
      lastname: this.props.fieldValues.lastname,
      mobile: this.props.fieldValues.mobile,
      bowlsClub: this.props.fieldValues.bowlsClub,
      postcode: this.props.fieldValues.postcode,
    };

    console.log(
      "firstname",
      this.props.fieldValues.firstname,
      this.props.fieldValues.lastname,
      this.props.fieldValues.bowlsClub,
      this.props.fieldValues.postcode,
      this.props.fieldValues.mobile
    );

    fire
      .auth()
      .createUserWithEmailAndPassword(data2.email, data2.password)
      .then(function (data) {
        firebase.firestore().collection("users").doc(data.user.uid).set({
          uid: data.user.uid,
          email: data2.email,
          firstname: that.props.fieldValues.firstname,
          lastname: data2.lastname,
          mobile: data2.mobile,
          bowlsClub: data2.bowlsClub,
          postcode: data2.postcode,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    // this.props.saveValues(data);
    //this.nextStep();
  },
});

export default LoginFields;
