import React, { Component } from "react";
import fire from "./config/fire";
import firebase from "firebase";
import "firebase/firestore";

class Stats extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
   // this.getFirstName = this.getFirstName.bind(this);
    this.state ={
//firstname: ""
    }
  }

  getWinningPercentage() {
    var that = this
    var Matches = that.state.singlesMatches
    console.log(Matches)
    var Wins = that.state.singlesMatchesWon
    console.log(Wins)
const winningPercentage2 = (Wins/Matches * 100)
console.log(winningPercentage2)

  }

  getFirstName() {

    var that = this
    
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
    
   var docRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid);

   docRef.get().then(function(doc) {

        that.setState({firstname: doc.data().firstname, endsWon: doc.data().endsWon, endsPlayed: doc.data().endsPlayed, });
        that.setState({ singlesMatches: doc.data().singlesMatches, singlesMatchesWon: doc.data().singlesMatchesWon })
        that.setState({ matches: doc.data().totalMatches, matchesWon: doc.data().matchesWon })
        that.setState({ singlesEndsWon: doc.data().singlesEndsWon, singlesEndsPlayed: doc.data().singlesEndsPlayed, });
        that.setState({ singlesWinningPercentage: ((doc.data().singlesMatchesWon) / (doc.data().singlesMatches) * 100), winningPercentage: ((doc.data().matchesWon) / (doc.data().totalMatches) * 100).toFixed(0), endWinningPercentage: ((doc.data().endsWon) / (doc.data().endsPlayed) * 100).toFixed(0), singlesEndWinningPercentage: ((doc.data().singlesEndsWon) / (doc.data().singlesEndsPlayed) * 100).toFixed(0) })
    
}).catch(function(error) {
    console.log("Error getting document:", error);
}).catch(function(error) {
  console.log("Got an error: ",error);
  });
} else {
  // No user is signed in.
}
});
} 


  logOut() {
    fire.auth().signOut();
  }

componentDidMount() {
  this.getFirstName()
  this.getWinningPercentage()
}

  render() { 
    return (
      <div>

    <h1>Your Stats</h1>

    <h2>Overall</h2>

    <p>You have played {this.state.matches} matches and won {this.state.matchesWon} </p>
    <p>Winning percentage {this.state.winningPercentage}%</p>
    <p>You have played {this.state.endsPlayed} ends and won {this.state.endsWon}. You've won  {this.state.endWinningPercentage}% of ends. </p>

    <h2>Singles</h2>

<p>You have played {this.state.singlesMatches} singles matches and won {this.state.singlesMatchesWon} </p>
<p>Winning percentage {this.state.singlesWinningPercentage}%</p>
<p>You have played {this.state.singlesEndsPlayed} ends and won {this.state.singlesEndsWon}. You've won  {this.state.singlesEndWinningPercentage}% of ends. </p>

    
        
        <button className="btn btn-primary" onClick={this.logOut}>Log Out</button>
      </div>
    );
  }
}

export default Stats;
