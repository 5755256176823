import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navigation from "./Navigation";
import Welcome from "./Welcome";
import Stats from "./Stats";
import About from "./About";
import Startsinglesmatch from "./MatchComponents/Startsinglesmatch";
import fire from "./config/fire";
import Savedmatchesmain from "./Savedmatchesmain";
import Savedmatchesproportionalmain from "./Savedmatchesproportionalmain";
import Savedmatchespairsmain from "./Savedmatchespairsmain";
import Savedmatchestriplesmain from "./Savedmatchestriplesmain";
import Savedmatchesfoursmain from "./Savedmatchesfoursmain";
import ForgotPassword from "./ForgotPassword";
import ResumeSingles from "./MatchComponents/ResumeSingles";
import Startpairsmatch from './PairsMatchComponents/Startpairsmatch';
import ResumePairs from "./PairsMatchComponents/ResumePairs";
import Starttriplesmatch from './TriplesMatchComponents/Starttriplesmatch';
import ResumeTriples from "./TriplesMatchComponents/ResumeTriples";
import Startfoursmatch from './FoursMatchComponents/Startfoursmatch';
import ResumeFours from "./FoursMatchComponents/ResumeFours";
import Startproportionalmatch from './ProportionalMatchComponents/Startproportionalmatch';
import ResumeProportional from "./ProportionalMatchComponents/ResumeProportional";

class Home extends Component {
 

  logOut(){
fire.auth().signOut();
  }

  render() {
    return (
      <div>
        <BrowserRouter>
          
            <Navigation />

            <Switch>
              <Route path="/" component={Welcome} exact />
              <Route path="/login" component={Welcome} />
              <Route path="/account" component={About} />
              <Route path="/startsinglesmatch" component={Startsinglesmatch} />
              <Route path="/startproportionalmatch" component={Startproportionalmatch} />
              <Route path="/startpairsmatch" component={Startpairsmatch} />
              <Route path="/starttriplesmatch" component={Starttriplesmatch} />
              <Route path="/startfoursmatch" component={Startfoursmatch} />
              <Route path="/Savedmatches" component={Savedmatchesmain} />
              <Route path="/Savedmatchesproportional" component={Savedmatchesproportionalmain} />
              <Route path="/Savedmatchespairs" component={Savedmatchespairsmain} />
              <Route path="/Savedmatchestriples" component={Savedmatchestriplesmain} />
              <Route path="/Savedmatchesfours" component={Savedmatchesfoursmain} />
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route path="/resumeSingles" component={ResumeSingles} />
              <Route path="/resumeProportional" component={ResumeProportional} />
              <Route path="/resumePairs" component={ResumePairs} />
              <Route path="/resumeTriples" component={ResumeTriples} />
              <Route path="/resumeFours" component={ResumeFours} />
              <Route path="/stats" component={Stats} exact />
            </Switch>
         
        </BrowserRouter>
      </div>
    );
  }
}
export default Home;
