import React from "react";
import "firebase/firestore";



var createReactClass = require("create-react-class");

var ProportionalPlayerNameFields = require("./proportionalPlayerNameFields").default;
var ProportionalMatchDetails = require("./ProportionalMatchDetails2").default;
var ProportionalMatchScreen = require("./ProportionalMatchScreen").default;
var SuccessProportional = require("./SuccessProportional").default;
var assign = require("object-assign");


var matchValues = {
  playerOneName: null,
  playerTwoName: null,
  matchVenue: null,
  gameEndsAt: 100,
  gameType: null,
  winnerName: null,
  matchID: null,
};

var Startproportionalmatch = createReactClass({
  getInitialState: function () {
    return {
      step: 1,
    };
  },

  saveValues: function (field_value) {
    return function () {
      matchValues = assign({}, matchValues, field_value);
    }.bind(this)();
  },

  nextStep: function () {
    this.setState({
      step: this.state.step + 1,
    });
  },

  // Same as nextStep, but decrementing
  previousStep: function () {
    this.setState({
      step: this.state.step - 1,
    });
  },




  ShowStep: function () {
    switch (this.state.step) {
      case 1:
        return (
          <ProportionalPlayerNameFields
            matchValues={matchValues}
            nextStep={this.nextStep}
            saveValues={this.saveValues}
          />
        );
      case 2:
        return (
          <ProportionalMatchDetails
            matchValues={matchValues}
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            saveValues={this.saveValues}
            //commenceMatch={this.commenceMatch}
          />
        );
      case 3:
        return (
          <ProportionalMatchScreen
            matchValues={matchValues}
            previousStep={this.previousStep}
            nextStep={this.nextStep}
            saveValues={this.saveValues}

          />
        );
      case 4:
        return <SuccessProportional matchValues={matchValues} />;
    }
  },


    render: function () {
      var style = {
        width: (this.state.step / 4) * 100 + "%",
      };
  
      return <main>{this.ShowStep()}</main>;
    },
  });

export default Startproportionalmatch;


