import React, { Fragment } from "react";
import firebase from "firebase";
import VirtualList from 'react-virtual-list';


const options = {
  //container: this.refs.container, // use this scrollable element as a container
  initialState: {
    firstItemIndex: 0, // show first ten items
   lastItemIndex: 50,  // during initial render
  },
};




class Scorecard extends React.Component {
  
  constructor(props) {
    super(props);
    this.getMatchList = this.getMatchList.bind(this);
    this.MyList = this.MyList.bind(this);
    this.cumulativeSum = this.cumulativeSum.bind(this);
    this.state = {
      data: "",
      endTimes: [],
      playerOneEndScores: [],
      playerTwoEndScores: [],
      playerOneCumulative: [],
      playerTwoCumulative: [],
        }
  }

cumulativeSum = (sum => value => sum += value)(0);



MyList = ({
    virtual,
    itemHeight,
  }) => (
    <Fragment style={virtual.style}>
      {virtual.items.map((item, index) => (
        <tr key={`item_${index}`} style={{height: itemHeight}}>
         <td>{index + 1}</td><td> {this.state.playerOneEndScores[index]} ({this.state.playerOneEndScores.reduce(function(r, a) {
  r.push((r.length && r[r.length - 1] || 0) + a);
  return r;
}, [])[index]}) </td><td>{this.state.playerTwoEndScores[index]} ({this.state.playerTwoEndScores.reduce(function(r, a) {
  r.push((r.length && r[r.length - 1] || 0) + a);
  return r;
}, [])[index]})</td><td> {item} </td>
        </tr>
      ))}
</Fragment>
  );

MyVirtualList = VirtualList(options)(this.MyList);

  getMatchList() {

    var that = this
    
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
   var docRef = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection(that.props.matchValues.matchType).doc(that.props.matchValues.matchID);

   docRef.get().then(function(doc) {
    if (doc.exists) {
        console.log("Document data:", doc.data());
        
     that.setState({ data:doc.data() })
     that.setState ({ endTimes:doc.data().endTimes })
     console.log("player one scores", doc.data().playerOneEndScores)
     that.setState ({ playerOneEndScores:doc.data().playerOneEndScores })
    that.setState ({ playerTwoEndScores:doc.data().playerTwoEndScores })
     console.log("end times:", that.state.endTimes)
     console.log(that.state.playerTwoEndScores)
    } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
    }
}).catch(function(error) {
    console.log("Error getting document:", error);
});
} else {
  // No user is signed in.
}
});
} 

componentDidMount() {
  this.getMatchList()
}


  render() {
    
    return (
      <div>
       <h1>Scorecard</h1>
       <p>
{this.state.data.playerOneName } v { this.state.data.playerTwoName } at { this.state.data.matchVenue }
       </p>
       <table class="table table-sm table-bordered">
       <thead class="thead-light">
         <tr>
           <th width="10%" scope="col">End Number</th>
           <th width="30%" scope="col">{this.state.data.playerOneName }</th>
           <th width="30%" scope="col">{ this.state.data.playerTwoName }</th>
           <th width="30%" scope="col">End Finished at</th>
         </tr>
         </thead>
       <this.MyVirtualList
  items={this.state.endTimes}
  items2={this.state.playerOneEndScores}
  items3={this.state.playerTwoEndScores}
/>
</table>

       <p>{ this.state.data.playerOneScore} - { this.state.data.playerTwoScore } after { this.state.data.endNumber } ends.</p>
       <p>Match started: {this.state.data.matchDate}</p>
       <p>Match concluded: {this.state.data.matchEnd}</p>
       <button onClick={this.props.previousStep} className="btn btn-primary">Back</button>
      </div>
    );
  }
}
export default Scorecard;
