import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";

var config = {
  apiKey: "AIzaSyCz85jhmevBnFrZTPTpcNLr6U2TgBXUy9Q",
  authDomain: "shotbowl-bf3b5.firebaseapp.com",
  databaseURL: "https://shotbowl-bf3b5.firebaseio.com",
  projectId: "shotbowl-bf3b5",
  storageBucket: "shotbowl-bf3b5.appspot.com",
  messagingSenderId: "260724639689",
  appId: "1:260724639689:web:7afe3ec2c7bc3fec7d0a18",
  measurementId: "G-D51KVPC20L",
};

const fire = firebase.initializeApp(config);

export default fire;
