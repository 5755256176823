import React from "react";
import firebase from "firebase";
import ReactDOM from "react-dom";


class ForgotPassword extends React.Component {

  constructor(props) {
    
    super(props);
    this.resetPassword = this.resetPassword.bind(this)
  }



resetPassword() {
  var emailAddress = ReactDOM.findDOMNode(this.refs.email).value
firebase.auth().sendPasswordResetEmail(emailAddress).then(function() {
  // Email sent.
}).catch(function(error) {
  // An error happened.
});
};



  

  render() {
    return (
      <div>

<p>Enter your email below to receive a password reset email.</p>

<input
        class="form-control"
          type="text"
          ref="email"
          placeholder="Your email"
            style={{ marginBottom: "0.8rem" }}
       
        />
        <button onClick={this.resetPassword} className="btn btn-primary">Reset Password</button>
      </div>
    );
  }
}
export default ForgotPassword;
