import React, { Component } from "react";
import fire from "./components/config/fire";
import Home from "./components/Home";
import Getstarted from "./components/Getstarted";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        this.setState({ user });
        //localStorage.setItem('user', user.uid)
      } else {
        this.setState({ user: null });
        //localStorage.removeItem('user');
      }
    });
  }

  render() {
    return (
      <div className="container">
        <div>{this.state.user ? <Home /> : <Getstarted />}</div>
      </div>
    );
  }
}

export default App;
