import Foursmatch from './foursmatch'
var React = require("react");


 class FoursMatchScreen extends React.Component {

  createTeamOneName() {
    if (this.props.matchValues.playerOneName === "")
    {
      return (this.props.matchValues.teamOneSkip + "'s team")
     }
    else {
      return (this.props.matchValues.playerOneName)
    }
      }

      createTeamTwoName() {
        if (this.props.matchValues.playerTwoName === "")
        {
          console.log(this.props.matchValues.teamTwoSkip)
          return (this.props.matchValues.teamTwoSkip + "'s team")
          
         }
        else {
          return (this.props.matchValues.playerTwoName)
        }
          }

    
  render() {    

        
    return (
        
        
        <div>
      <Foursmatch playerOneNameEntry={ this.createTeamOneName() }
      teamOneSkipEntry={ this.props.matchValues.teamOneSkip }
      teamOneThirdEntry={ this.props.matchValues.teamOneThird }
      teamOneSecondEntry={ this.props.matchValues.teamOneSecond }
      teamOneLeadEntry={ this.props.matchValues.teamOneLead }
      teamTwoSkipEntry={ this.props.matchValues.teamTwoSkip }
      teamTwoThirdEntry={ this.props.matchValues.teamTwoThird } 
      teamTwoSecondEntry={ this.props.matchValues.teamTwoSecond } 
      teamTwoLeadEntry={ this.props.matchValues.teamTwoLead } 
                    playerTwoNameEntry={ this.createTeamTwoName() } 
                    matchVenueEntry= { this.props.matchValues.matchVenue }
                    gameEndsAtEntry= { this.props.matchValues.gameEndsAt }
                    gameTypeEntry= { this.props.matchValues.gameType }
                    matchIDEntry = {  this.props.matchValues.matchID }
                    nextStep = { this.props.nextStep }
                    saveValues = { this.props.saveValues }
                    playerSelectEntry = { this.props.matchValues.playerSelect }
      />
      </div>
  )
    }
}


export default FoursMatchScreen