import React from "react";
import "firebase/firestore";


var createReactClass = require("create-react-class");

var react = require("react");
var SavedMatchListPairs = require("./SavedMatchComponents/SavedMatchListPairs").default;
var SavedMatchDetails = require("./SavedMatchComponents/SavedMatchDetails").default;
var Scorecard = require("./SavedMatchComponents/Scorecard").default;
var assign = require("object-assign");


var matchValues = {
  matchID: null,
  matchType: null,
};

var Savedmatchespairsmain = createReactClass({
  getInitialState: function () {
    return {
      step: 1,
    };
  },

  saveValues: function (field_value) {
    return function () {
      matchValues = assign({}, matchValues, field_value);
    }();
  },

  nextStep: function () {
    this.setState({
      step: this.state.step + 1,
    });
  },

  // Same as nextStep, but decrementing
  previousStep: function () {
    this.setState({
      step: this.state.step - 1,
    });
  },




  ShowStepSaved: function () {
    console.log(this.state.step)
    switch (this.state.step) {
      case 1:
        return (
          <SavedMatchListPairs matchValues={matchValues}
          nextStep={this.nextStep}
          previousStep={this.previousStep}
          saveValues={this.saveValues}/>
          
        );
      case 2:
        return (
          <SavedMatchDetails
            matchValues={matchValues}
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            saveValues={this.saveValues}
            //commenceMatch={this.commenceMatch}
          />
        );
      case 3:
        return (
          <Scorecard
            matchValues={matchValues}
            previousStep={this.previousStep}
            nextStep={this.nextStep}
            saveValues={this.saveValues}

          />
        );

    }
  },


    render: function () {
  console.log(this.state.step)
      return <main>
          { this.ShowStepSaved() }
          </main>;
    },
  });

export default Savedmatchespairsmain;


