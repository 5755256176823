import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import fire from "./config/fire";
import Signup from "./Signup";
import LoginScreen from './login'
import Header from './Header'
import ForgotPassword from "./ForgotPassword";



class Getstarted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMode: "getstarted",
      email: "",
      password: "",
    };
  }

  login(e) {
    e.preventDefault();
    fire
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((u) => {})
      .catch((error) => {
        console.log(error);
      });
  }



  render() {

    return (
      <div className="container">
<div>
        <BrowserRouter>
          <div>
          <Header nextState={this.nextState}/>
            <Switch>
              <Route path="/" component={Signup} exact />
              <Route path="/login" component={LoginScreen} />
              <Route path="/forgotpassword" component={ForgotPassword} />
            </Switch>
          </div>
        </BrowserRouter>

            </div>
          </div>
    
    );
  }
}

//const pos = new Getstarted()
//export default pos

export default Getstarted;
