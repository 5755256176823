import React from "react";
import firebase from "firebase";
import "firebase/firestore";




class Triplesmatch extends React.Component {
  state = {
    playerOneScore: 0,
    existingPlayerOneScore: 0,
    playerTwoScore: 0,
    existingPlayerTwoScore: 0,
    playerOneScoreArray: [],
    existingPlayerOneScoreArray: [],
    playerTwoScoreArray: [],
    existingPlayerTwoScoreArray: [],
    Ends: 0,
    existingEnds: 0,
    winnerName: "",
    matchID: "",
    endTimes: [],
    existingEndTimes: [],
    playerOneEndsWon: 0,
    playerTwoEndsWon: 0,
    existingPlayerOneEndsWon: 0,
    existingPlayerTwoEndsWon: 0
  };


createMatchRecord() {

  const increment = firebase.firestore.FieldValue.increment(1);

  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").add({
    playerOneName: this.props.playerOneNameEntry, 
    playerTwoName: this.props.playerTwoNameEntry,
    teamOneLead: this.props.teamOneLeadEntry, 
    teamOneSecond: this.props.teamOneSecondEntry, 
    teamOneSkip: this.props.teamOneSkipEntry, 
    teamTwoLead: this.props.teamTwoLeadEntry, 
    teamTwoSecond: this.props.teamTwoSecondEntry, 
    teamTwoSkip: this.props.teamTwoSkipEntry, 
    matchVenue: this.props.matchVenueEntry,
    gameType: this.props.gameTypeEntry,
       gameEndsAt: this.props.gameEndsAtEntry,
       matchComplete: false, 
    matchDate: Date()
  }).then(docRef => {
  this.setState({ matchID: docRef.id })

  if (this.props.playerSelectEntry !== "noStats")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    totalMatches: increment,
    triplesMatches: increment,
    }, {merge:true})
   var data3 = {
       matchID: docRef.id,
   }}
   else {

   }

   var data3 = {
       matchID: docRef.id,
   }

   this.props.saveValues(data3);
   
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(docRef.id).set({
matchID: docRef.id
    }, {merge: true})
}).catch((error) => {
  console.log(error);
});


}


undoButton = () => {
  //set State to previous score
      this.setState({ 
    playerOneScore: this.state.existingPlayerOneScore,
    playerTwoScore: this.state.existingPlayerTwoScore,
    playerOneScoreArray: this.state.existingPlayerOneScoreArray,
    playerTwoScoreArray: this.state.existingPlayerTwoScoreArray,
    endTimes: this.state.existingEndTimes,
    Ends: this.state.existingEnds,
    playerOneEndsWon: this.state.existingPlayerOneEndsWon,
    playerTwoEndsWon: this.state.existingPlayerTwoEndsWon
  })
  //Update Firestore
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
    playerOneScore: this.state.existingPlayerOneScore,
    playerTwoScore: this.state.existingPlayerTwoScore,
    endNumber: this.state.existingEnds,
    playerOneEndScores: this.state.existingPlayerOneScoreArray,
    playerTwoEndScores: this.state.existingPlayerTwoScoreArray,
    endTimes: this.state.existingEndTimes,
    }, {merge: true})
    .catch((error) => {
    console.log(error);
    });
  
  
    }
  
    playerOneOneUp = () => {
  
      // Set the existing values as current values before we change anything in case we have to reset.
  
      this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
      this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
      this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
      
      // Now update the scores, ends and ends player each one. 
  
      this.setState({ playerOneScore: this.state.playerOneScore + 1 });
      this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
      this.setState({ Ends: this.state.Ends + 1 });
  
      // Now update the arrays - scores so far and end times
  
  
      var newArray = this.state.playerOneScoreArray.concat(1);
      var otherNewArray = this.state.playerTwoScoreArray.concat(0); 
      var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
      this.setState({ playerOneScoreArray: newArray })
      this.setState({ playerTwoScoreArray: otherNewArray })
      this.setState({ endTimes: timeArray })
  
  
      //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore + 1,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
    if (this.state.playerOneScore + 1 >= this.props.gameEndsAtEntry) 
    {
      this.setState({ winnerName: this.props.playerOneNameEntry })
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
        // Update Overall Win Stats
  
        // first set all of the incremental values
  
        const increment = firebase.firestore.FieldValue.increment(1);
        const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
        const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 1);
        const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
        const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
        const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
        // now if the logged in player is Player One, update their Firestore stats. 
  
        if (this.props.playerSelectEntry === "playerOne")
          
          {
            firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
            matchesWon: increment,
            triplesMatchesWon: increment,
            endsPlayed: EndsPlayedIncrement,
            triplesEndsPlayed: EndsPlayedIncrement,
            endsWon: playerOneEndsWonIncrement,
            triplesEndsWon: playerOneEndsWonIncrement,
            pointsWon: playerOnePointsWonIncrement,
            triplesPointsWon:playerOnePointsWonIncrement,
            pointsAgainst: playerTwoPointsWonIncrement,
            triplesPointsAgainst: playerTwoPointsWonIncrement
            }, {merge:true})
          }
  
          // Do the same if they are Player Two 
  
          if (this.props.playerSelectEntry === "playerTwo")
  {
  
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
      endsPlayed: EndsPlayedIncrement,
      triplesEndsPlayed: EndsPlayedIncrement,
      endsWon: playerTwoEndsWonIncrement,
      triplesEndsWon: playerTwoEndsWonIncrement,
      pointsWon: playerTwoPointsWonIncrement,
      triplesPointsWon: playerTwoPointsWonIncrement,
      pointsAgainst:playerOnePointsWonIncrement,
      triplesPointsAgainst:playerOnePointsWonIncrement
      }, {merge:true})
  }
  
  
           else {
        // Do nothing if there are no stats for this game.
           }
  
           var data = {
            winnerName: this.props.playerOneNameEntry,
            playerOneScore: this.state.playerOneScore  + 1,
        playerTwoScore: this.state.playerTwoScore,
        playerOneEndsWon: this.state.playerOneEndsWon + 1,
        playerTwoEndsWon: this.state.playerTwoEndsWon,
        ends: this.state.Ends + 1,
          };
  
  this.props.nextStep();
  this.props.saveValues(data);
    } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
    if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
    {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
      if (this.state.playerOneScore + 1 > this.state.playerTwoScore) {
        this.setState({ winnerName: this.props.playerOneNameEntry });
        console.log('Player One Wins')
        firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
          winnerName: this.props.playerOneNameEntry,
          matchEnd: new Date().toLocaleTimeString(),
          matchComplete: true, 
          }, {merge: true})
          .catch((error) => {
          console.log(error);
          });
  
             // Set the increments for overall stats
  
        const increment = firebase.firestore.FieldValue.increment(1);
        const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
        const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 1);
        const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore); 
        const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
        const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
        // Now if the logged in Player is Player One, update their overall stats. 
  
        if (this.props.playerSelectEntry === "playerOne")
          
          {
            firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
            matchesWon: increment,
            triplesMatchesWon: increment,
            endsPlayed: EndsPlayedIncrement,
            triplesEndsPlayed: EndsPlayedIncrement,
            endsWon: playerOneEndsWonIncrement,
            triplesEndsWon: playerOneEndsWonIncrement,
            pointsAgainst: playerTwoPointsWonIncrement,
            triplesPointsAgainst: playerTwoPointsWonIncrement,
            pointsWon: playerOnePointsWonIncrement,
            triplesPointsWon: playerOnePointsWonIncrement
            }, {merge:true})
          }
  
          // If the logged in player is Player Two, updated their stats. 
  
  
  
          if (this.props.playerSelectEntry === "playerTwo")
  {
  
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
      endsPlayed: EndsPlayedIncrement,
      triplesEndsPlayed: EndsPlayedIncrement,
      endsWon: playerTwoEndsWonIncrement,
      triplesEndsWon: playerTwoEndsWonIncrement,
      pointsAgainst: playerOnePointsWonIncrement,
      triplesPointsAgainst: playerOnePointsWonIncrement,
      pointsWon: playerTwoPointsWonIncrement,
      triplesPointsWon: playerTwoPointsWonIncrement
      }, {merge:true})
  }
  
  
           else {
        // Do nothing if this match is excluded from stats
           }
          
          
          
           data = {
            winnerName: this.props.playerOneNameEntry,
            playerOneScore: this.state.playerOneScore  + 1,
        playerTwoScore: this.state.playerTwoScore,
        playerOneEndsWon: this.state.playerOneEndsWon + 1,
        playerTwoEndsWon: this.state.playerTwoEndsWon,
        ends: this.state.Ends + 1,
          };
          this.props.nextStep();
  this.props.saveValues(data);
   
        }
          
  
           // Now if Player Two's score his higher
  
  
      if (this.state.playerOneScore + 1 < this.state.playerTwoScore) {
        this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
    winnerName: this.props.playerTwoNameEntry,
    matchEnd: new Date().toLocaleTimeString(),
    matchComplete: true, 
    }, {merge: true})
    .catch((error) => {
    console.log(error);
    });
    
     // Set the increments
  
     const increment = firebase.firestore.FieldValue.increment(1);
     const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
     const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
     const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
     const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 1);
     const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
     // And if the Logged in player is Player Two 
  
     if (this.props.playerSelectEntry === "playerTwo")
       
       {
         firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
         matchesWon: increment,
         triplesMatchesWon: increment,
         endsPlayed: EndsPlayedIncrement,
         triplesEndsPlayed: EndsPlayedIncrement,
         endsWon: playerTwoEndsWonIncrement,
         triplesEndsWon: playerTwoEndsWonIncrement,
         pointsAgainst: playerOnePointsWonIncrement,
         triplesPointsAgainst: playerOnePointsWonIncrement,
         pointsWon:playerTwoPointsWonIncrement,
         triplesPointsWon:playerTwoPointsWonIncrement
         }, {merge:true})
       }
  
       // and if the logged in player is Player One (and they've lost)
  
       if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
   endsPlayed: EndsPlayedIncrement,
   triplesEndsPlayed: EndsPlayedIncrement,
   endsWon: playerOneEndsWonIncrement,
   triplesEndsWon: playerOneEndsWonIncrement,
   pointsAgainst: playerTwoPointsWonIncrement,
   triplesPointsAgainst: playerTwoPointsWonIncrement,
   pointsWon: playerOnePointsWonIncrement,
   triplesPointsWon: playerOnePointsWonIncrement
   }, {merge:true})
  }
  
  
        else {
     // do nothing if this match is excluded from stats. 
        }
  
  
  
        data = {
          winnerName: this.props.playerTwoNameEntry,
          playerOneScore: this.state.playerOneScore  + 1,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
  
  // Finally, if scores are level. 
  
  
      if (this.state.playerOneScore + 1 === this.state.playerTwoScore) {
        this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
    winnerName: "Match Drawn",
    matchEnd: new Date().toLocaleTimeString(),
    matchComplete: true, 
    }, {merge: true})
    .catch((error) => {
    console.log(error);
    });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 1);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
    
    {
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
      matchesDrawn: increment,
      triplesMatchesDrawn: increment,
      endsPlayed: EndsPlayedIncrement,
      triplesEndsPlayed: EndsPlayedIncrement,
      endsWon: playerOneEndsWonIncrement,
      triplesEndsWon: playerOneEndsWonIncrement,
      pointsAgainst: playerTwoPointsWonIncrement,
      triplesPointsAgainst: playerTwoPointsWonIncrement,
      pointsWon: playerOnePointsWonIncrement,
      triplesPointsWon: playerOnePointsWonIncrement
      }, {merge:true})
    }
  // and finally if the logged in player is player two 
  
    if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
    winnerName: "Match Drawn",
    playerOneScore: this.state.playerOneScore  + 1,
  playerTwoScore: this.state.playerTwoScore,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
      }
  
    }
  }
  
  };
  
  
  playerOneTwoUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerOneScore: this.state.playerOneScore + 2 });
    this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(2);
    var otherNewArray = this.state.playerTwoScoreArray.concat(0); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore + 2,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerOneScore + 2 >= this.props.gameEndsAtEntry) 
  {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 2);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 2,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore + 2 > this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 2);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 2,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore + 2 < this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 2);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore  + 2,
    playerTwoScore: this.state.playerTwoScore,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore + 2 === this.state.playerTwoScore) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 2);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 2,
  playerTwoScore: this.state.playerTwoScore,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerOneThreeUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerOneScore: this.state.playerOneScore + 3 });
    this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(3);
    var otherNewArray = this.state.playerTwoScoreArray.concat(0); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore + 3,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerOneScore + 3 >= this.props.gameEndsAtEntry) 
  {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 3);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 3,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore + 3 > this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 3);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 3,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore + 3 < this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 3);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore  + 3,
    playerTwoScore: this.state.playerTwoScore,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore + 3 === this.state.playerTwoScore) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 3);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 3,
  playerTwoScore: this.state.playerTwoScore,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerOneFourUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerOneScore: this.state.playerOneScore + 4 });
    this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(4);
    var otherNewArray = this.state.playerTwoScoreArray.concat(0); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore + 4,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerOneScore + 4 >= this.props.gameEndsAtEntry) 
  {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 4);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 4,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore + 4 > this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 4);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 4,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore + 4 < this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 4);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore  + 4,
    playerTwoScore: this.state.playerTwoScore,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore + 4 === this.state.playerTwoScore) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 4);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 4,
  playerTwoScore: this.state.playerTwoScore,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerOneFiveUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerOneScore: this.state.playerOneScore + 5 });
    this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(5);
    var otherNewArray = this.state.playerTwoScoreArray.concat(0); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore + 5,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerOneScore + 5 >= this.props.gameEndsAtEntry) 
  {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 5);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 5,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore + 5 > this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 5);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 5,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore + 5 < this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 5);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore  + 5,
    playerTwoScore: this.state.playerTwoScore,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore + 5 === this.state.playerTwoScore) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 5);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 5,
  playerTwoScore: this.state.playerTwoScore,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerOneSixUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerOneScore: this.state.playerOneScore + 6 });
    this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(6);
    var otherNewArray = this.state.playerTwoScoreArray.concat(0); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore + 6,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerOneScore + 6 >= this.props.gameEndsAtEntry) 
  {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 6);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore + 6,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore + 6 > this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 6);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 6,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore + 6 < this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 6);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore  + 6,
    playerTwoScore: this.state.playerTwoScore,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore + 3 === this.state.playerTwoScore) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 6);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 6,
  playerTwoScore: this.state.playerTwoScore,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerOneSevenUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerOneScore: this.state.playerOneScore + 7 });
    this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(7);
    var otherNewArray = this.state.playerTwoScoreArray.concat(0); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore + 7,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerOneScore + 7 >= this.props.gameEndsAtEntry) 
  {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 7);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 7,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore + 7 > this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 7);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 7,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore + 7 < this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 7);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore  + 7,
    playerTwoScore: this.state.playerTwoScore,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore + 7 === this.state.playerTwoScore) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 7);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 7,
  playerTwoScore: this.state.playerTwoScore,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerOneEightUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerOneScore: this.state.playerOneScore + 8 });
    this.setState({ playerOneEndsWon: this.state.playerOneEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(8);
    var otherNewArray = this.state.playerTwoScoreArray.concat(0); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore + 8,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerOneScore + 8 >= this.props.gameEndsAtEntry) 
  {
    this.setState({ winnerName: this.props.playerOneNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerOneNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 8);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 8,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore + 8 > this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 8);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore  + 8,
      playerTwoScore: this.state.playerTwoScore,
      playerOneEndsWon: this.state.playerOneEndsWon + 1,
      playerTwoEndsWon: this.state.playerTwoEndsWon,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore + 8 < this.state.playerTwoScore) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 8);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore  + 8,
    playerTwoScore: this.state.playerTwoScore,
    playerOneEndsWon: this.state.playerOneEndsWon + 1,
    playerTwoEndsWon: this.state.playerTwoEndsWon,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore + 8 === this.state.playerTwoScore) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon + 1);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore + 8);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore  + 8,
  playerTwoScore: this.state.playerTwoScore,
  playerOneEndsWon: this.state.playerOneEndsWon + 1,
  playerTwoEndsWon: this.state.playerTwoEndsWon,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerTwoOneUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerTwoScore: this.state.playerTwoScore + 1 });
    this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(0);
    var otherNewArray = this.state.playerTwoScoreArray.concat(1); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore + 1,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerTwoScore + 1 >= this.props.gameEndsAtEntry) 
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 1);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    triplesMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerTwoNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 1,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore > this.state.playerTwoScore + 1) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 1); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 1,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore < this.state.playerTwoScore + 1) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 1);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore,
    playerTwoScore: this.state.playerTwoScore + 1,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore === this.state.playerTwoScore + 1) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 1);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore,
  playerTwoScore: this.state.playerTwoScore + 1,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerTwoTwoUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerTwoScore: this.state.playerTwoScore + 2 });
    this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(0);
    var otherNewArray = this.state.playerTwoScoreArray.concat(2); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore + 2,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerTwoScore + 2 >= this.props.gameEndsAtEntry) 
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 2);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    triplesMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerTwoNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 2,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore > this.state.playerTwoScore + 2) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 2); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 2,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore < this.state.playerTwoScore + 2) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 2);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore,
    playerTwoScore: this.state.playerTwoScore + 2,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore === this.state.playerTwoScore + 2) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 2);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore,
  playerTwoScore: this.state.playerTwoScore + 2,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerTwoThreeUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerTwoScore: this.state.playerTwoScore + 3 });
    this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(0);
    var otherNewArray = this.state.playerTwoScoreArray.concat(3); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore + 3,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerTwoScore + 3 >= this.props.gameEndsAtEntry) 
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 3);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    triplesMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerTwoNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 3,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore > this.state.playerTwoScore + 3) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 3); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 3,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore < this.state.playerTwoScore + 3) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 3);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore,
    playerTwoScore: this.state.playerTwoScore + 3,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore === this.state.playerTwoScore + 3) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 3);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore,
  playerTwoScore: this.state.playerTwoScore + 3,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerTwoFourUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerTwoScore: this.state.playerTwoScore + 4 });
    this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(0);
    var otherNewArray = this.state.playerTwoScoreArray.concat(4); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore + 4,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerTwoScore + 4 >= this.props.gameEndsAtEntry) 
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 4);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    triplesMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerTwoNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 4,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore > this.state.playerTwoScore + 4) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 4); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 4,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore < this.state.playerTwoScore + 4) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 4);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore,
    playerTwoScore: this.state.playerTwoScore + 4,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore === this.state.playerTwoScore + 4) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 4);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore,
  playerTwoScore: this.state.playerTwoScore + 4,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerTwoFiveUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerTwoScore: this.state.playerTwoScore + 5 });
    this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(0);
    var otherNewArray = this.state.playerTwoScoreArray.concat(5); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore + 5,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerTwoScore + 5 >= this.props.gameEndsAtEntry) 
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 5);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    triplesMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerTwoNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 5,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore > this.state.playerTwoScore + 5) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 5); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 5,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore < this.state.playerTwoScore + 5) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 5);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore,
    playerTwoScore: this.state.playerTwoScore + 5,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore === this.state.playerTwoScore + 5) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 5);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore,
  playerTwoScore: this.state.playerTwoScore + 5,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerTwoSixUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerTwoScore: this.state.playerTwoScore + 6 });
    this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(0);
    var otherNewArray = this.state.playerTwoScoreArray.concat(6); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore + 6,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerTwoScore + 6 >= this.props.gameEndsAtEntry) 
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 6);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    triplesMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerTwoNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 6,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore > this.state.playerTwoScore + 6) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 6); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 6,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore < this.state.playerTwoScore + 6) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 6);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore,
    playerTwoScore: this.state.playerTwoScore + 6,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore === this.state.playerTwoScore + 6) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 6);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore,
  playerTwoScore: this.state.playerTwoScore + 6,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerTwoSevenUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerTwoScore: this.state.playerTwoScore + 7 });
    this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(0);
    var otherNewArray = this.state.playerTwoScoreArray.concat(7); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore + 7,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerTwoScore + 7 >= this.props.gameEndsAtEntry) 
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 7);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    triplesMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerTwoNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 7,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore > this.state.playerTwoScore + 7) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 7); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 7,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore < this.state.playerTwoScore + 7) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 7);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore,
    playerTwoScore: this.state.playerTwoScore + 7,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore === this.state.playerTwoScore + 7) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 7);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore,
  playerTwoScore: this.state.playerTwoScore + 7,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };
  
  playerTwoEightUp = () => {
  
    // Set the existing values as current values before we change anything in case we have to reset.
  
    this.setState({ existingPlayerOneScore: this.state.playerOneScore, existingPlayerOneScoreArray: this.state.playerOneScoreArray, existingPlayerOneEndsWon: this.state.playerOneEndsWon  })
    this.setState({ existingPlayerTwoScore: this.state.playerTwoScore, existingPlayerTwoScoreArray: this.state.playerTwoScoreArray, existingPlayerTwoEndsWon: this.state.playerTwoEndsWon })
    this.setState({ existingEnds: this.state.Ends, existingEndTimes: this.state.endTimes })
    
    // Now update the scores, ends and ends player each one. 
  
    this.setState({ playerTwoScore: this.state.playerTwoScore + 8 });
    this.setState({ playerTwoEndsWon: this.state.playerTwoEndsWon + 1 });
    this.setState({ Ends: this.state.Ends + 1 });
  
    // Now update the arrays - scores so far and end times
  
  
    var newArray = this.state.playerOneScoreArray.concat(0);
    var otherNewArray = this.state.playerTwoScoreArray.concat(8); 
    var timeArray = this.state.endTimes.concat( new Date().toLocaleTimeString() );
    this.setState({ playerOneScoreArray: newArray })
    this.setState({ playerTwoScoreArray: otherNewArray })
    this.setState({ endTimes: timeArray })
  
  
    //update the lices scores in Firestore
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  playerOneScore: this.state.playerOneScore,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  playerTwoScore: this.state.playerTwoScore + 8,
  endNumber: this.state.Ends + 1,
  playerOneEndScores: newArray,
  playerTwoEndScores: otherNewArray,
  endTimes: timeArray,
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Check if either player has passed the winning score and finish the match if they have. 
  
  if (this.props.gameTypeEntry === 'Points')
  {
  if (this.state.playerTwoScore + 8 >= this.props.gameEndsAtEntry) 
  {
    console.log('Player Two Wins')
    this.setState({ winnerName: this.props.playerTwoNameEntry })
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
      winnerName: this.props.playerTwoNameEntry,
      matchEnd: new Date().toLocaleTimeString(),
      matchComplete: true, 
      }, {merge: true})
      .catch((error) => {
      console.log(error);
      });
  
      // Update Overall Win Stats
  
      // first set all of the incremental values
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 8);
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // now if the logged in player is Player One, update their Firestore stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon:playerOnePointsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement
          }, {merge:true})
        }
  
        // Do the same if they are Player Two 
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesWon: increment,
    triplesMatchesWon: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement,
    pointsAgainst:playerOnePointsWonIncrement,
    triplesPointsAgainst:playerOnePointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if there are no stats for this game.
         }
  
         var data = {
          winnerName: this.props.playerTwoNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 8,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
  
  this.props.nextStep();
  this.props.saveValues(data);
  } 
  
  
  
  }
  
  // Now check if the game has reached the final number of ends
  
  if (this.props.gameTypeEntry === 'Ends') {
  if (this.state.Ends >= this.props.gameEndsAtEntry - 1)
  {
  
  // if it has, check which player has the higher score. If it's player one declare them the winner and finish the match. 
  
    if (this.state.playerOneScore > this.state.playerTwoScore + 8) {
      this.setState({ winnerName: this.props.playerOneNameEntry });
      console.log('Player One Wins')
      firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
        winnerName: this.props.playerOneNameEntry,
        matchEnd: new Date().toLocaleTimeString(),
        matchComplete: true, 
        }, {merge: true})
        .catch((error) => {
        console.log(error);
        });
  
           // Set the increments for overall stats
  
      const increment = firebase.firestore.FieldValue.increment(1);
      const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
      const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
      const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 8); 
      const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);    
      const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
      // Now if the logged in Player is Player One, update their overall stats. 
  
      if (this.props.playerSelectEntry === "playerOne")
        
        {
          firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
          matchesWon: increment,
          triplesMatchesWon: increment,
          endsPlayed: EndsPlayedIncrement,
          triplesEndsPlayed: EndsPlayedIncrement,
          endsWon: playerOneEndsWonIncrement,
          triplesEndsWon: playerOneEndsWonIncrement,
          pointsAgainst: playerTwoPointsWonIncrement,
          triplesPointsAgainst: playerTwoPointsWonIncrement,
          pointsWon: playerOnePointsWonIncrement,
          triplesPointsWon: playerOnePointsWonIncrement
          }, {merge:true})
        }
  
        // If the logged in player is Player Two, updated their stats. 
  
  
  
        if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerTwoEndsWonIncrement,
    triplesEndsWon: playerTwoEndsWonIncrement,
    pointsAgainst: playerOnePointsWonIncrement,
    triplesPointsAgainst: playerOnePointsWonIncrement,
    pointsWon: playerTwoPointsWonIncrement,
    triplesPointsWon: playerTwoPointsWonIncrement
    }, {merge:true})
  }
  
  
         else {
      // Do nothing if this match is excluded from stats
         }
        
        
        
         data = {
          winnerName: this.props.playerOneNameEntry,
          playerOneScore: this.state.playerOneScore,
      playerTwoScore: this.state.playerTwoScore + 8,
      playerOneEndsWon: this.state.playerOneEndsWon,
      playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
      ends: this.state.Ends + 1,
        };
        this.props.nextStep();
  this.props.saveValues(data);
  
      }
        
  
         // Now if Player Two's score his higher
  
  
    if (this.state.playerOneScore < this.state.playerTwoScore + 8) {
      this.setState({ winnerName: this.props.playerTwoNameEntry })
  console.log('Player Two Wins')
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: this.props.playerTwoNameEntry,
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
   // Set the increments
  
   const increment = firebase.firestore.FieldValue.increment(1);
   const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
   const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
   const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 8);
   const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
   const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
   // And if the Logged in player is Player Two 
  
   if (this.props.playerSelectEntry === "playerTwo")
     
     {
       firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
       matchesWon: increment,
       triplesMatchesWon: increment,
       endsPlayed: EndsPlayedIncrement,
       triplesEndsPlayed: EndsPlayedIncrement,
       endsWon: playerTwoEndsWonIncrement,
       triplesEndsWon: playerTwoEndsWonIncrement,
       pointsAgainst: playerOnePointsWonIncrement,
       triplesPointsAgainst: playerOnePointsWonIncrement,
       pointsWon:playerTwoPointsWonIncrement,
       triplesPointsWon:playerTwoPointsWonIncrement
       }, {merge:true})
     }
  
     // and if the logged in player is Player One (and they've lost)
  
     if (this.props.playerSelectEntry === "playerOne")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerOneEndsWonIncrement,
  triplesEndsWon: playerOneEndsWonIncrement,
  pointsAgainst: playerTwoPointsWonIncrement,
  triplesPointsAgainst: playerTwoPointsWonIncrement,
  pointsWon: playerOnePointsWonIncrement,
  triplesPointsWon: playerOnePointsWonIncrement
  }, {merge:true})
  }
  
  
      else {
   // do nothing if this match is excluded from stats. 
      }
  
  
  
      data = {
        winnerName: this.props.playerTwoNameEntry,
        playerOneScore: this.state.playerOneScore,
    playerTwoScore: this.state.playerTwoScore + 8,
    playerOneEndsWon: this.state.playerOneEndsWon,
    playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
    ends: this.state.Ends + 1,
      };
      this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  // Finally, if scores are level. 
  
  
    if (this.state.playerOneScore === this.state.playerTwoScore + 8) {
      this.setState({ winnerName: "Match Drawn" })
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("Triples").doc(this.state.matchID).set({
  winnerName: "Match Drawn",
  matchEnd: new Date().toLocaleTimeString(),
  matchComplete: true, 
  }, {merge: true})
  .catch((error) => {
  console.log(error);
  });
  
  // Set the increments
  
  const increment = firebase.firestore.FieldValue.increment(1);
  const EndsPlayedIncrement = firebase.firestore.FieldValue.increment(this.state.Ends + 1);
  const playerOneEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneEndsWon);
  const playerTwoPointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoScore + 8);
  const playerOnePointsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerOneScore);
  const playerTwoEndsWonIncrement = firebase.firestore.FieldValue.increment(this.state.playerTwoEndsWon + 1)
  
  // If the logged in player is PlayerTwo
  
  if (this.props.playerSelectEntry === "playerOne")
  
  {
    firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
    matchesDrawn: increment,
    triplesMatchesDrawn: increment,
    endsPlayed: EndsPlayedIncrement,
    triplesEndsPlayed: EndsPlayedIncrement,
    endsWon: playerOneEndsWonIncrement,
    triplesEndsWon: playerOneEndsWonIncrement,
    pointsAgainst: playerTwoPointsWonIncrement,
    triplesPointsAgainst: playerTwoPointsWonIncrement,
    pointsWon: playerOnePointsWonIncrement,
    triplesPointsWon: playerOnePointsWonIncrement
    }, {merge:true})
  }
  // and finally if the logged in player is player two 
  
  if (this.props.playerSelectEntry === "playerTwo")
  {
  
  firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).set({
  matchesDrawn: increment,
  triplesMatchesDrawn: increment,
  endsPlayed: EndsPlayedIncrement,
  triplesEndsPlayed: EndsPlayedIncrement,
  endsWon: playerTwoEndsWonIncrement,
  triplesEndsWon: playerTwoEndsWonIncrement,
  pointsAgainst: playerOnePointsWonIncrement,
  triplesPointsAgainst: playerOnePointsWonIncrement,
  pointsWon: playerTwoPointsWonIncrement,
  triplesPointsWon: playerTwoPointsWonIncrement
  }, {merge:true})
  }
  
  
  
  data = {
  winnerName: "Match Drawn",
  playerOneScore: this.state.playerOneScore,
  playerTwoScore: this.state.playerTwoScore + 8,
  playerOneEndsWon: this.state.playerOneEndsWon,
  playerTwoEndsWon: this.state.playerTwoEndsWon + 1,
  ends: this.state.Ends + 1,
  };
  this.props.nextStep();
  this.props.saveValues(data);
  
    }
  
  }
  }
  
  };

componentDidMount() {
  this.createMatchRecord()
}

  render() {
    return (
      <div>
        <table class="table table-sm table-borderless" style={{textAlign:"center"}}>
          <tr  style={{cellPadding: "5px"}}>
            <td width="50%">
        
          <h3>{ this.props.playerOneNameEntry }</h3>
          <h2>
          {this.state.playerOneScore}</h2>
          </td>
          <td width="50%">
          
        <h3>{ this.props.playerTwoNameEntry }</h3>
        <h2>
          {this.state.playerTwoScore}
        </h2>

        </td>
        </tr>
        <tr>
          <td colspan="2">
          
         <p>Ends: {this.state.Ends}</p>
       

          </td>
        </tr>
        <tr>

<td colspan="2"> <p>Match Venue:  { this.props.matchVenueEntry }</p>
        <p>Game Ends After { this.props.gameEndsAtEntry } { this.props.gameTypeEntry }</p></td>

     </tr>
        <tr>
          <td>
          { this.props.playerOneNameEntry }
          </td>
          <td>
          { this.props.playerTwoNameEntry }
          </td>
        </tr>


<tr>

  <td>
  <button
          onClick={this.playerOneOneUp}
          className="btn btn-secondary btn-sm"
        >
          +1
        </button>
        </td>
        <td>
        <button
          onClick={this.playerTwoOneUp}
          className="btn btn-secondary btn-sm"
        >
          +1
        </button>
          </td>
</tr>
<tr>
<td>
<button
          onClick={this.playerOneTwoUp}
          className="btn btn-secondary btn-sm"
        >
          +2
        </button>
</td>
<td>
<button
          onClick={this.playerTwoTwoUp}
          className="btn btn-secondary btn-sm"
        >
          +2
        </button>

</td>

</tr>

<tr>
<td>

<button
          onClick={this.playerOneThreeUp}
          className="btn btn-secondary btn-sm"
        >
          +3
        </button>
</td>
<td>

<button
          onClick={this.playerTwoThreeUp}
          className="btn btn-secondary btn-sm"
        >
          +3
        </button>

</td>

</tr>
<tr>
<td>
       
       
        <button
          onClick={this.playerOneFourUp}
          className="btn btn-secondary btn-sm"
        >
          +4
        </button>
  </td>

  <td>
       
  
        <button
          onClick={this.playerTwoFourUp}
          className="btn btn-secondary btn-sm"
        >
          +4
        </button>

  </td>
</tr>
<tr>
<td>
       
       
        <button
          onClick={this.playerOneFiveUp}
          className="btn btn-secondary btn-sm"
        >
          +5
        </button>
  </td>

  <td>
       
  
        <button
          onClick={this.playerTwoFiveUp}
          className="btn btn-secondary btn-sm"
        >
          +5
        </button>

  </td>
</tr>
<tr>
<td>
       
       
        <button
          onClick={this.playerOneSixUp}
          className="btn btn-secondary btn-sm"
        >
          +6
        </button>
  </td>

  <td>
       
  
        <button
          onClick={this.playerTwoSixUp}
          className="btn btn-secondary btn-sm"
        >
          +6
        </button>

  </td>
</tr>
<tr>
<td>
       
       
        <button
          onClick={this.playerOneSevenUp}
          className="btn btn-secondary btn-sm"
        >
          +7
        </button>
  </td>

  <td>
       
  
        <button
          onClick={this.playerTwoSevenUp}
          className="btn btn-secondary btn-sm"
        >
          +7
        </button>

  </td>
</tr>
<tr>
<td>
       
       
        <button
          onClick={this.playerOneEightUp}
          className="btn btn-secondary btn-sm"
        >
          +8
        </button>
  </td>

  <td>
       
  
        <button
          onClick={this.playerTwoEightUp}
          className="btn btn-secondary btn-sm"
        >
          +8
        </button>

  </td>
</tr>

<tr>
  <td colspan="2">
<button className="btn btn-primary btn-sm" onClick={this.undoButton}>Undo</button>
  </td>
</tr>

</table>

      </div>
    );
  }

  getBadgeClasses() {
    let classes = "badge m-2 badge-";
    classes += this.state.playerOneScore === 0 ? "warning" : "primary";
    return classes;
  }
  formatCount() {
    const { playerOneScore } = this.state;
    return playerOneScore;
  }
}

export default Triplesmatch;
