import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navigation from "./Navigation";
import Home from "./Home";
import About from "./About";
import Startsinglesmatch from "./MatchComponents/Startsinglesmatch";
import Savedmatchesmain from "./Savedmatchesmain";

// This navigation doesn't do anything use Home.JS

class Main extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <div>
            <Navigation />
            <Switch>
            
              <Route path="/" component={Home} exact />
              <Route path="/about" component={About} />
              <Route path="/startsinglesmatch" component={Startsinglesmatch} />
              <Route path="/Savedmatches" component={Savedmatchesmain} />
            </Switch>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default Main;
